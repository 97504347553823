import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import Web3 from "web3";
import TronWeb from "tronweb";
import * as XLSX from "xlsx";
const cookies = new Cookies();

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 6,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const WithdrawalRequests = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [teamReq, setTeamReq] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState();
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();
  const [upd, setUpd] = useState(false);
  const [current_param, setCurrent_param] = useState("all");
  const [text, setText] = useState({});

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Address from",
        "Address to",
        "Amount",
        "Network",
        "Hash",
        "Domain",
        "Creation time",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.address_from,
              e.address_to,
              e.amount,
              e.type,
              e.hash,
              e.domain,
              new Date(e.creation_time).toLocaleString(),
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transfer records");
    XLSX.writeFile(wb, "transfer_records_export.xlsx");
  };

  const get_transaction_status = async (row) => {
    let hash = row.hash;
    let type = row.type;
    const creation_time = new Date(row.creation_time).getTime();
    const current_time = new Date().getTime();
    const diff = current_time - creation_time;

    if (type === "USDT BEP20") {
      const web3 = new Web3(
        new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
      );

      try {
        const r = await web3.eth.getTransactionReceipt(hash);

        if (r) {
          return 1;
        }
      } catch (err) {
        if (diff / 1000 / 60 > 5) {
          return -1;
        }
        return 0;
      }
    } else if (type === "USDT ERC20" || type === "USDC ERC20") {
      const web3 = new Web3(
        new Web3.providers.HttpProvider(
          "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
        )
      );

      try {
        const r = await web3.eth.getTransactionReceipt(hash);

        if (r) {
          return 1;
        }
      } catch (err) {
        if (diff / 1000 / 60 > 5) {
          return -1;
        }
        return 0;
      }
    } else {
      const tronWeb = new TronWeb({
        fullHost: "https://api.trongrid.io",
      });

      try {
        const r = await tronWeb.trx.getTransactionInfo(hash);

        if (r === {}) {
          if (diff / 1000 / 60 > 5) {
            return -1;
          }
          return 0;
        }

        if (r.receipt.result === "SUCCESS") {
          return 1;
        }
      } catch (err) {
        if (diff / 1000 / 60 > 5) {
          return -1;
        }
        return 0;
      }
    }

    if (diff / 1000 / 60 > 5) {
      return -1;
    }

    return 0;
  };

  // useEffect(() => {
  //   if (requests.length > 0) {
  //     let temp = requests;
  //     temp.map(async (row) => {
  //       let status = await get_transaction_status(row);
  //       console.log(status, row)
  //       if (status !== 1) {
  //         temp.pop(row);
  //       }
  //     });

  //     setRequests([])

  //     setTimeout(() => {
  //       setRequests(temp);
  //     }, 1000);
  //   }
  // }, [requests]);

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getTransferCombo")
        .then((res) => {
          //console.log(res.data);
          // setRequests(res.data);

          if (res.data.length > 0) {
            let temp = res.data;
            let del = [];

            temp.map(async (row) => {
              let status = await get_transaction_status(row);
              if (status === -1 || status === 0) {
                del.push(row);
              }
            });

            setTimeout(() => {
              setRequests(temp.filter((e) => !del.includes(e)));
            }, 1000);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [upd]);

  useEffect(() => {
    if (current_param === "All") {
      setUpd(!upd);
    } else if (current_param === "Admin") {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/getTransfer")
          .then((res) => {
            //console.log(res.data);
            // setRequests(res.data);

            if (res.data.length > 0) {
              let temp = res.data;
              let del = [];

              temp.map(async (row) => {
                let status = await get_transaction_status(row);
                if (status === -1 || status === 0) {
                  del.push(row);
                }
              });

              setTimeout(() => {
                setRequests(temp.filter((e) => !del.includes(e)));
              }, 1000);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/getTransferParnter", {
            id: parseInt(current_param),
          })
          .then((res) => {
            //console.log(res.data);
            // setRequests(res.data);

            if (res.data.length > 0) {
              let temp = res.data;
              let del = [];

              temp.map(async (row) => {
                let status = await get_transaction_status(row);
                if (status === -1 || status === 0) {
                  del.push(row);
                }
              });

              setTimeout(() => {
                setRequests(temp.filter((e) => !del.includes(e)));
              }, 1000);
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [current_param]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const param = () => {
    let temp = [];

    temp.push("All", "Admin");
    temp.push(
      ...Object.keys(
        requests.reduce((acc, item) => {
          const key = item.partner_id;

          if (key == 1) return acc;

          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {})
      )
    );

    return temp;
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text["Transfers"]} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={param()}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setCurrent_param(newValue);
                } else {
                  setUpd(!upd);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500 }}
              aria-label="custom pagination table"
              className="statistics_table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {text.UID}{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{text["Address from"]}</StyledTableCell>
                  <StyledTableCell>{text["Address to"]}</StyledTableCell>
                  <StyledTableCell>{text["Amount"]}</StyledTableCell>
                  <StyledTableCell>{text["Network"]}</StyledTableCell>
                  <StyledTableCell>{text.hash}</StyledTableCell>
                  <StyledTableCell>Domain</StyledTableCell>
                  <StyledTableCell>{text["creation_time"]}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.partner_id}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.address_from}</TableCell>
                    <TableCell>{row.address_to}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.hash}</TableCell>
                    <TableCell>{row.domain}</TableCell>
                    <TableCell>
                      {new Date(row.creation_time).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default WithdrawalRequests;
