import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Input } from "@mui/material";
import axios from "axios";
import lang from "../lang/language.json";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import TronWeb from "tronweb";
import Web3 from "web3";

const cookies = new Cookies();

const Settings = () => {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [id, setId] = useState(0);
  const [ids, setIds] = useState([]);
  const [user, setUser] = useState({});
  const [upd, setUpd] = useState(false);
  const [role, setRole] = useState("");
  const [admins, setAdmins] = useState([]);
  const [private_keys, setPrivate_keys] = useState();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getPKbep = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    const new_account = web3.eth.accounts.create();
    const privateKey = new_account.privateKey;
    const address = new_account.address;

    return { privateKey, address };
  };

  const getPKerc = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
      )
    );

    const new_account = web3.eth.accounts.create();
    const privateKey = new_account.privateKey;
    const address = new_account.address;

    return { privateKey, address };
  };

  const getPKtrc = async () => {
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    const new_account = await tronWeb.createAccount();
    const privateKey = new_account.privateKey;
    const address = new_account.address.base58;

    return { privateKey, address };
  };

  const getPKeys = async () => {
    const bep = await getPKbep();
    const erc = await getPKerc();
    const trc = await getPKtrc();

    return { bep, erc, trc };
  };

  useEffect(() => {
    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }

    try {
      axios.post(process.env.REACT_APP_API_URL + "/getIds").then((res) => {
        if (res.status === 200) {
          setIds(res.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [upd]);

  useEffect(() => {
    if (user) {
      if (!role === "admin") {
        navigate("/settings");
      }
    }
  }, [user, navigate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const getPK = () => {
    try {
      (async () => {
        if (!private_keys) {
          const pkeys = await getPKeys();
          console.log(pkeys);
          setPrivate_keys(pkeys);
        }
      })();
    } catch (err) {
      console.log(err);
    }
  };

  const setNewData = () => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/setDefaultData", {
          user_id: id,
        })
        .then((res) => {
          if (res.status === 200) {
            setError(text["User registered successfully"]);
            setOpen2(true);
          }
        })
        .catch((err) => {
          setError(text["User not registered"]);
          setOpen2(true);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const register_user = () => {
    if (id === 0) {
      setError(text["Please select user"]);
      setOpen2(true);
      return;
    }

    if (login === "" || password === "") {
      setError(text["Login and password must be filled"]);
      setOpen2(true);
      return;
    }

    if (!private_keys) {
      setError(text["Please generate private keys"]);
      setOpen2(true);
      return;
    }

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/regUser", {
          login: login,
          password: password,
          id: id,
          role: "partner",
          private_keys: JSON.stringify(private_keys),
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.error) {
              setError(text["Something went wrong, error"]);
              setOpen2(true);
            } else {
              axios
              .post(process.env.REACT_APP_API_URL + "/setPK", {
                id: id,
                private_keys: JSON.stringify(private_keys),
              })
              .then((res) => {
                if (res.status === 200) {
                  setNewData();
                  setUpd(!upd);
                  //window.location.reload();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            }
          }
        })
        .catch((err) => {
          setError("User already exists");
          setOpen2(true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const generatePassword = () => {
    let password = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";

    for (let i = 0; i < 10; i++)
      password += possible.charAt(Math.floor(Math.random() * possible.length));

    setPassword(password);
  };

  const generateUsername = () => {
    axios
      .get("https://api.api-ninjas.com/v1/randomuser", {
        headers: {
          "X-Api-Key": "Z6soWRArmmBy0vCn8TEcTQ==5Lh3aaME3F7ZVy8u",
        },
      })
      .then((res) => {
        setLogin(res.data.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text["register_partner"]} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack spacing={2} width={800}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={ids.map(
                (option) =>
                  `${option.id} - ${option.address} - ${
                    option.nickname ? option.nickname : "No nickname"
                  }`
              )}
              sx={{ width: 800 }}
              onChange={(event, newValue) => {
                newValue ? setId(parseInt(newValue.split(" - ")[0])) : setId(0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.select_user}
                  placeholder={text["Insert user id or address for find"]}
                />
              )}
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {text.Login}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={generateUsername}
                      edge="end"
                    >
                      <AutorenewIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label={text.Login}
                onChange={(e) => setLogin(e.target.value)}
                value={login}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {text.Password}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={generatePassword}
                        edge="end"
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                }
                label={text.Password}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </FormControl>

            <TextField
              type="text"
              label={text["Private key"] + " (BEP20)"}
              disabled
              value={private_keys ? private_keys.bep.privateKey : ""}
              id="outlined-basic"
              variant="outlined"
              fullWidth
              focused={
                private_keys
                  ? private_keys.bep.privateKey !== ""
                    ? true
                    : false
                  : false
              }
              onChange={(e) =>
                setPrivate_keys({ ...private_keys, bep: e.target.value })
              }
            />

            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              type="text"
              label={text["Private key"] + " (ERC20)"}
              value={private_keys ? private_keys.erc.privateKey : ""}
              focused={
                private_keys
                  ? private_keys.erc.privateKey !== ""
                    ? true
                    : false
                  : false
              }
              onChange={(e) =>
                setPrivate_keys({ ...private_keys, erc: e.target.value })
              }
            />

            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              type="text"
              label={text["Private key"] + " (TRC20)"}
              value={private_keys ? private_keys.trc.privateKey : ""}
              focused={
                private_keys
                  ? private_keys.trc.privateKey !== ""
                    ? true
                    : false
                  : false
              }
              onChange={(e) =>
                setPrivate_keys({ ...private_keys, trc: e.target.value })
              }
            />

            <Button variant="contained" onClick={getPK}>
              {text["Get private key"]}
            </Button>

            <Button variant="contained" onClick={register_user}>
              {text.Register}
            </Button>
          </Stack>
        </Stack>
      </Content>
    </div>
  );
};

export default Settings;
