import { Box, Button, Stack } from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import lang from "../lang/language.json";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "@mui/material/Tooltip";
const cookies = new Cookies();

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState({});
  const [languages, setLanguages] = useState([
    { name: "English", code: "en" },
    { name: "Française", code: "fr" },
    { name: "简体中文 (simplified)", code: "zh" },
    { name: "繁體中文 (traditional)", code: "zht" },
    { name: "Русский", code: "ru" },
    { name: "Español", code: "es" },
    { name: "日本", code: "ja" },
    { name: "한국인", code: "ko" },
    { name: "Tiếng Việt", code: "vi" },
    { name: "bahasa Indonesia", code: "id" },
    { name: "Türkçe", code: "tr" },
    { name: "ประเทศไทย", code: "th" },
    { name: "عربي", code: "ar" },
    { name: "hollandsk", code: "nl" },
    { name: "Portugal", code: "pt" },
  ]);

  let navigate = useNavigate();

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("refreshToken")) {
      navigate("/");
    }

    //change title
    document.title = "System Panel";
  }, [navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get("lang");

    if (!localStorage.getItem("language")) {
      // get language from browser
      const browserLang = navigator.language;

      if (urlLang) {
        if (languages.find((lang) => lang.code === urlLang)) {
          localStorage.setItem("language", urlLang);
          setText(lang[localStorage.getItem("language")]);
          return;
        }
      } else if (browserLang) {
        if (languages.find((lang) => lang.code === browserLang.split("-")[0])) {
          localStorage.setItem("language", browserLang.split("-")[0]);
          setText(lang[localStorage.getItem("language")]);
          return;
        }
      }

      localStorage.setItem("language", "en");
    } else {
      if (urlLang) {
        if (languages.find((lang) => lang.code === urlLang)) {
          localStorage.setItem("language", urlLang);

          setText(lang[localStorage.getItem("language")]);
          return;
        }
      } else if (localStorage.getItem("language")) {
        setText(lang[localStorage.getItem("language")]);
      }
    }

    cookies.remove("refreshToken");
  }, []);

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const checkLogin = () => {
    if (login === "" || password === "") {
      alert("Please enter your login or password");
      return false;
    } else {
      axios.defaults.withCredentials = true;
      axios
        .post(process.env.REACT_APP_API_URL + "/login", {
          login: login,
          password: password,
        })
        .then((res) => {
          if (res.status === 200) {
            const cookies = new Cookies();
            cookies.set("refreshToken", res.data.refreshToken, {
              path: "/",
              maxAge: 24 * 60 * 60 * 1000,
              sameSite: true,
              secure: false, 
            });

            localStorage.setItem("role", res.data.user.role);
            setError("Login successful");

            axios
              .post(process.env.REACT_APP_API_URL + "/getPoolData", {
                id: res.data.user.id,
              })
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("data_pool", JSON.stringify(res.data));
                }
              })
              .catch((error) => {
                console.log(error);
              });

            axios
              .post(process.env.REACT_APP_API_URL + "/getData", {
                id: res.data.user.id,
              })
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem(
                    "data_settings",
                    JSON.stringify(res.data)
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });

            handleClick();
            navigate("/settings");
          }
        })
        .catch((err) => {
          try {
            setError(err.response.data.message);
          } catch (e) {
            setError("Something went wrong");
          }
          handleClick();
        });

      return true;
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          style: {
            maxHeight: 45 * 4.5,
            width: "20ch",
          },
        }}
      >
        {languages.map((option) => (
          <MenuItem
            key={option.code}
            selected={option.code === localStorage.getItem("language")}
            onClick={() => {
              handleClose2();
              localStorage.setItem("language", option.code);
              window.location.reload();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          padding: "50px 50px 0 0",
        }}
      >
        <Tooltip title="Language">
          <IconButton
            aria-label="more"
            color="inherit"
            id="long-button"
            aria-controls={open2 ? "long-menu" : undefined}
            aria-expanded={open2 ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick2}
          >
            <Stack>
              <LanguageIcon />
            </Stack>
          </IconButton>
        </Tooltip>
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={error}
        action={action}
      />

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="80%"
      >
        <Box width={500}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom component="div">
              {text["System Panel"]}
            </Typography>
          </Stack>

          <TextField
            fullWidth
            id="outlined-basic"
            label={text["Username"]}
            variant="outlined"
            onChange={(e) => setLogin(e.target.value)}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label={text["Password"]}
            type="password"
            variant="outlined"
            margin="dense"
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => checkLogin()}
          >
            {text["Login"]}
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

export default Login;
