import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Content from "./components/Content";
import Header from "./components/Header";

const cookies = new Cookies();

export default function App({ title }) {
  const [open, setOpen] = React.useState(true);

  let navigate = useNavigate();

  React.useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <Header open={open} setOpen={setOpen} title={title} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Typography paragraph>
            This is admin panel
          </Typography>
        </Stack>
      </Content>
    </Box>
  );
}
