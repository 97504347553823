import React from "react";
import axios from "axios";
import TronWeb from "tronweb";
import settins from "../private_keys.json";

const SetTronAddress = () => {
  const [tronAddress, setTronAddress] = React.useState("");
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    const privateKey = settins.REACT_APP_PRIVATE_KEY_TRC20_PERMISSION;
    if (privateKey !== "") {
      const tronWeb = new TronWeb({
        fullHost: "https://api.trongrid.io",
        privateKey: privateKey,
      });
      
      setTronAddress(tronWeb.defaultAddress.base58);
      axios
        .post(process.env.REACT_APP_API_URL + "/transferOwnershipAddress", {
          id: 1,
          address: tronWeb.defaultAddress.base58,
        })
        .then((res) => {
          setMsg("Success");
        })
        .catch((error) => {
          setMsg("Error, wallet not found");
        });
    } else {
      setMsg("No Private Key");
    }
  }, []);

  return (
    <div>
      <h1>Set Tron Address {tronAddress}</h1>
      {msg ? <p>{msg}</p> : null}
    </div>
  );
};

export default SetTronAddress;
