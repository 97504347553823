import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";

import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import lang from "../lang/language.json";

const cookies = new Cookies();

const AddScript = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();

  const [text, setText] = useState({});
  
  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getScript", {
        id: curr_user_id,
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [curr_user_id]);

  const updateData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/setScript", {
        id: curr_user_id,
        script: data,
      })
      .then((res) => {
        if (res.status === 200) {
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.add_script_to_site} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="h6" width={200}>
              {text.script}
            </Typography>
            <TextField
              multiline
              rows={3}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={data}
              onChange={(e) => {
                setData(e.target.value);
              }}
            />
          </Stack>
          <Button variant="contained" onClick={updateData}>
            {text.save}
          </Button>
        </Stack>
      </Content>
    </div>
  );
};

export default AddScript;
