import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";

import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Button,
  Container,
  List,
  MenuItem,
  Select,
  TableHead,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function UserPopup({ id, close }) {
  const [user_id, setUserId] = useState(id);
  const [user, setUser] = useState({});
  const [nickname, setNickname] = useState("");
  const [authorizedAddress, setAuthorizedAddress] = useState("");
  const [miningPoolRewardMethod, setMiningPoolRewardMethod] = useState(0);
  const [customThreshold, setCustomThreshold] = useState(1);
  const [settlementRandomRewardInterval, setSettlementRandomRewardInterval] =
    useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [minimumWithdrawalAmount, setMinimumWithdrawalAmount] = useState(0);
  const [teamSize, setTeamSize] = useState(0);
  const [teamCumulativeRevenue, setTeamCumulativeRevenue] = useState(0);
  const [teamEarningsTodayUSDT, setTeamEarningsTodayUSDT] = useState(0);
  const [teamEarningsTodayToken, setTeamEarningsTodayToken] = useState(0);
  const [cumulativeIncomeToken, setCumulativeIncomeToken] = useState(0);
  const [earningsTodayUSDT, setEarningsTodayUSDT] = useState(0);
  const [earningsTodayToken, setEarningsTodayToken] = useState(0);
  const [usdtAuthorized, setUsdtAuthorized] = useState(0);
  const [usdcAuthorized, setUsdcAuthorized] = useState(0);
  const [busdAuthorized, setBusdAuthorized] = useState(0);
  const [userType, setUserType] = useState(0);
  const [all_users, setAllUsers] = useState([]);

  const [text, setText] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getUser", {
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        cookies.remove("refreshToken");
        navigate("/login");
      });

    axios
      .post(process.env.REACT_APP_API_URL + "/getAllUsersClient")
      .then((res) => {
        let ids = [];
        res.data.data.map((user) => {
          ids.push(user.id);
        });
        setAllUsers(ids);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getClientCustomData", {
        id: id,
      })
      .then((res) => {
        setNickname(res.data.nickname);
        setAuthorizedAddress(res.data.authorized_address);
        setMiningPoolRewardMethod(res.data.isCustompool);
        setCustomThreshold(res.data.threshold);
        setSettlementRandomRewardInterval(res.data.random_reward);
        setWalletAddress(res.data.address);
        setMinimumWithdrawalAmount(res.data.min_withwraw);
        setTeamSize(res.data.team_size);
        setTeamCumulativeRevenue(res.data.team_revenue_usdt);
        setTeamEarningsTodayUSDT(res.data.team_earnings_today_usdt);
        setTeamEarningsTodayToken(res.data.team_earnings_today_token);
        setCumulativeIncomeToken(res.data.team_revenue_token);
        setEarningsTodayUSDT(res.data.earnings_today_usdt);
        setEarningsTodayToken(res.data.earnings_today_token);
        setUsdtAuthorized(res.data.usdt_auth);
        setUsdcAuthorized(res.data.usdc_auth);
        setBusdAuthorized(res.data.busd_auth);
        setUserType(res.data.isTrial);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const click = () => {
    if (nickname === "") {
      alert(text["Please enter nickname"]);
      return;
    }

    if (authorizedAddress === "") {
      alert(text["Please enter authorized address"]);
      return;
    }

    if (walletAddress === "") {
      alert(text["Please enter wallet address"]);
      return;
    }

    if (minimumWithdrawalAmount === 0) {
      alert(text["Please enter minimum withdrawal amount"]);
      return;
    }

    if (user_id === "") {
      alert(text["Please enter user id"]);
      return;
    }

    if (all_users.includes(user_id) && user_id !== id) {
      alert(text["User id already exists"]);
      return;
    }

    axios
      .post(process.env.REACT_APP_API_URL + "/updateClientCustomData", {
        id: id,
        new_id: user_id,
        nickname: nickname,
        authorized_address: authorizedAddress,
        isCustompool: miningPoolRewardMethod,
        threshold: customThreshold,
        random_reward: settlementRandomRewardInterval,
        address: walletAddress,
        min_withwraw: minimumWithdrawalAmount,
        team_size: teamSize,
        team_revenue_usdt: teamCumulativeRevenue,
        team_earnings_today_usdt: teamEarningsTodayUSDT,
        team_earnings_today_token: teamEarningsTodayToken,
        revenue_token: cumulativeIncomeToken,
        earnings_today_usdt: earningsTodayUSDT,
        earnings_today_token: earningsTodayToken,
        usdt_auth: usdtAuthorized,
        usdc_auth: usdcAuthorized,
        busd_auth: busdAuthorized,
        isTrial: userType,
      })
      .then((res) => {
        if (res.status === 200) {
          close();
        } else {
          alert(text["error_updating_data"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["User nickname"]}
        variant="outlined"
        value={nickname}
        required
        onChange={(e) => setNickname(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["User id"]}
        variant="outlined"
        required
        value={user_id}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 1) {
            setUserId(parseInt(e.target.value));
          } else {
            setUserId(id);
          }
        }}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Authorized address"]}
        required
        variant="outlined"
        value={authorizedAddress}
        disabled={localStorage.getItem("role") === "admin" ? false : true}
        onChange={(e) => setAuthorizedAddress(e.target.value)}
      />
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Mining pool reward method"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Mining pool reward method"]}
          variant="outlined"
          margin="normal"
          value={miningPoolRewardMethod}
          onChange={(e) => setMiningPoolRewardMethod(e.target.value)}
        >
          <MenuItem value={0}>{text["Default"]}</MenuItem>
          <MenuItem value={1}>{text["Customize"]}</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Custom threshold"]}
        variant="outlined"
        value={customThreshold}
        onChange={(e) => {
          parseFloat(e.target.value) > 0
            ? setCustomThreshold(parseFloat(e.target.value))
            : setCustomThreshold(1);
        }}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Settlement random reward interval"]}
        variant="outlined"
        value={settlementRandomRewardInterval}
        onChange={(e) => setSettlementRandomRewardInterval(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Wallet address"]}
        variant="outlined"
        value={walletAddress}
        onChange={(e) => setWalletAddress(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Minimum withdrawal amount"]}
        variant="outlined"
        value={minimumWithdrawalAmount}
        onChange={(e) => setMinimumWithdrawalAmount(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team size"]}
        variant="outlined"
        value={teamSize}
        onChange={(e) => setTeamSize(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team cumulative revenue"]}
        variant="outlined"
        value={teamCumulativeRevenue}
        onChange={(e) => setTeamCumulativeRevenue(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team Earnings Today (USDT)"]}
        variant="outlined"
        value={teamEarningsTodayUSDT}
        onChange={(e) => setTeamEarningsTodayUSDT(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team earnings today (Token)"]}
        variant="outlined"
        value={teamEarningsTodayToken}
        onChange={(e) => setTeamEarningsTodayToken(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Cumulative income (Token)"]}
        variant="outlined"
        value={cumulativeIncomeToken}
        onChange={(e) => setCumulativeIncomeToken(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Earnings today (USDT)"]}
        variant="outlined"
        value={earningsTodayUSDT}
        onChange={(e) => setEarningsTodayUSDT(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Earnings today (Token)"]}
        variant="outlined"
        value={earningsTodayToken}
        onChange={(e) => setEarningsTodayToken(e.target.value)}
      />

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDT is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDT is authorized"]}
          variant="outlined"
          margin="normal"
          value={usdtAuthorized}
          onChange={(e) => setUsdtAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDC is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDC is authorized"]}
          variant="outlined"
          margin="normal"
          value={usdcAuthorized}
          onChange={(e) => setUsdcAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDT is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDT is authorized"]}
          variant="outlined"
          margin="normal"
          value={busdAuthorized}
          onChange={(e) => setBusdAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">{text["user"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["User type"]}
          variant="outlined"
          margin="normal"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
        >
          <MenuItem value={0}>{text["Normal"]}</MenuItem>
          <MenuItem value={1}>{text["Trial"]}</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={click}
      >
        {text["Submit"]}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Custom pool" ? (
        <UserPopup id={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select the status of the request</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [user, setUser] = useState({});

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [titleDialog, setTitleDialog] = useState("");

  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState("");
  const [sort, setSort] = useState({ sort: "usdt_balance", order: "asc" });

  const [text, setText] = useState({});

  useEffect(() => {
    let temp = requests;
    console.log(temp, sort);
    setRequests([]);
    setTimeout(() => {
      temp.sort((a, b) => {
        if (sort.order === "asc") {
          if (a[sort.sort] < b[sort.sort]) return -1;
          if (a[sort.sort] > b[sort.sort]) return 1;
        }
        if (sort.order === "desc") {
          if (a[sort.sort] > b[sort.sort]) return -1;
          if (a[sort.sort] < b[sort.sort]) return 1;
        }
        return 0;
      });
      setRequests(temp);
    }, 100);
  }, [sort]);

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "ID",
        "Referrer ID",
        "Wallet address",
        "Creation time",
        "Authorized address",
        "Authorization status",
        "Address type",
        "Account balance",
        "Wallet balance",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.id,
              e.inviter_id,
              e.address,
              new Date(e.register_date).toLocaleString(),
              e.authorized_address,
              e.authed == 1 ? "Authorization Disabled" : e.auth_records,
              e.auth_netw,
              e.usdt_amount,
              e.real_balance,
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Monitoring addresses");
    XLSX.writeFile(wb, "monitoring_addresses_export.xlsx");
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getMonitoring", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header
        open={open}
        setOpen={setOpen}
        title={text["monitoring_address"]}
      />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            {role === "admin" ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={all_users.map(
                  (option) => `${option.id} - ${option.login}`
                )}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  newValue
                    ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                    : setCurr_user_id(0);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select user" />
                )}
              />
            ) : null}

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={requests
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((option) => `${option.id} - ${option.address}`)}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRequests([
                    requests.filter(
                      (r) => r.id === parseInt(newValue.split(" - ")[0])
                    )[0],
                  ]);
                } else {
                  setCurr_user_id(user.id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter"
                  placeholder="Insert address or ID"
                />
              )}
            />
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text["Operate"]}</StyledTableCell>
                  <StyledTableCell>
                    ID{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{text["Wallet address"]}</StyledTableCell>
                  <StyledTableCell>{text["Referrer ID"]}</StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setSort({
                        sort: "register_date",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text.creation_time}
                      {sort.sort === "register_date" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    {text["Authorized address"]}
                  </StyledTableCell>
                  <StyledTableCell>
                    {text["Authorization status"]}
                  </StyledTableCell>
                  <StyledTableCell>{text["Address type"]} </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setSort({
                        sort: "usdt_amount",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text["Account Balance"]}
                      {sort.sort === "usdt_amount" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSort({
                        sort: "real_balance",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text["Wallet balance"]}
                      {sort.sort === "real_balance" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.address}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Custom pool");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>
                            {text["Pool customization"]}
                          </ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            window.open(
                              "https://etherscan.io/address/" + row.address,
                              "_blank"
                            );
                          }}
                        >
                          <ListItemText>
                            {text["USDT, USDC authorization query"]}
                          </ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            window.open(
                              "https://bscscan.com/address/" + row.address,
                              "_blank"
                            );
                          }}
                        >
                          <ListItemText>
                            {text["USDT authorization query"]}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>
                      {row.id}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.inviter_id}</TableCell>
                    <TableCell>
                      {new Date(row.register_date).toLocaleString()}
                    </TableCell>
                    <TableCell>{row.wallet_address}</TableCell>
                    <TableCell>
                      {row.auth_records
                        .replace(";", "\u000A")
                        .replace(";", "\u000A")}
                    </TableCell>
                    <TableCell>{row.auth_netw}</TableCell>
                    <TableCell>{row.usdt_amount}</TableCell>
                    <TableCell>{row.real_balance}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default Settings;
