import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";

import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Button,
  Container,
  List,
  MenuItem,
  Select,
  TableHead,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import lang from "../lang/language.json";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function Edit({ row, close }) {
  const [text, setText] = useState({});
  const lang2 = require("../lang/language.json");

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang2[localStorage.getItem("language")]);
  }, []);

  const [lang, setLang] = useState(row.lang);
  const [typePage, setTypePage] = useState(row.type_page);
  const [question, setQuestion] = useState(row.question);
  const [answer, setAnswer] = useState(row.answer);

  console.log(text)

  const save = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updFaq", {
        id: row.id,
        lang: lang,
        type_page: typePage,
        question: question,
        answer: answer,
      })
      .then((res) => {
        if (res.status === 200) {
          close({});
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        required
        label={text["Question"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <TextField
        id="standard-basic"
        required
        value={answer}
        multiline
        label={text["Answer"]}
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setAnswer(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Lang type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Lang type"]}
          variant="standard"
          margin="normal"
          value={lang}
          onChange={(e) => setLang(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="cn">Chinese (Simplified)</MenuItem>
          <MenuItem value="cnt">Chinese (Traditional)</MenuItem>
          <MenuItem value="jp">Japan</MenuItem>
          <MenuItem value="es">Spain</MenuItem>
          <MenuItem value="kr">Korean</MenuItem>
          <MenuItem value="vn">Vietnam</MenuItem>
          <MenuItem value="inr">Indonesia</MenuItem>
          <MenuItem value="tr">Turkey</MenuItem>
          <MenuItem value="ru">Russian</MenuItem>
          <MenuItem value="pt">Portugal</MenuItem>
          <MenuItem value="nl">Dutch</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
          <MenuItem value="th">Thailand</MenuItem>
          <MenuItem value="fr">France</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Page type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Page type"]}
          variant="standard"
          margin="normal"
          value={typePage}
          onChange={(e) => setTypePage(e.target.value)}
        >
          <MenuItem value="serve">{text["Service"]}</MenuItem>
          <MenuItem value="home">{text["Home"]}</MenuItem>
          <MenuItem value="exchange">{text["Exchange"]}</MenuItem>
        </Select>
      </FormControl>
      <Button
        autoFocus
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text["save"]}
      </Button>
    </Container>
  );
}

function Delete({ row, close }) {
  const [text, setText] = useState({});
  const lang2 = require("../lang/language.json");

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang2[localStorage.getItem("language")]);
  }, []);
  
  const deleteAsset = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/delFaq", {
        id: row.id,
      })
      .then((res) => {
        if (res.status === 200) {
          close({});
        }
      });
  };

  return (
    <Container>
      <Button
        variant="outlined"
        style={{ marginBottom: "10px" }}
        onClick={deleteAsset}
      >
        {text["Delete"]}
      </Button>
    </Container>
  );
}

function AddFaq({ close }) {
  const [lang, setLang] = useState("en");
  const [typePage, setTypePage] = useState("home");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const lang2 = require("../lang/language.json");

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang2[localStorage.getItem("language")]);
  }, []);

  const save = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/setFaq", {
        lang: lang,
        type_page: typePage,
        question: question,
        answer: answer,
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        if (res.status === 200) {
          close({});
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        required
        label={text["Question"]}
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setQuestion(e.target.value)}
      />
      <TextField
        id="standard-basic"
        label={text["Answer"]}
        variant="standard"
        required
        multiline
        fullWidth
        margin="normal"
        onChange={(e) => setAnswer(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Lang type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Lang type"]}
          variant="standard"
          margin="normal"
          value={lang}
          onChange={(e) => setLang(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="cn">Chinese (Simplified)</MenuItem>
          <MenuItem value="cnt">Chinese (Traditional)</MenuItem>
          <MenuItem value="jp">Japan</MenuItem>
          <MenuItem value="es">Spain</MenuItem>
          <MenuItem value="kr">Korean</MenuItem>
          <MenuItem value="vn">Vietnam</MenuItem>
          <MenuItem value="inr">Indonesia</MenuItem>
          <MenuItem value="tr">Turkey</MenuItem>
          <MenuItem value="ru">Russian</MenuItem>
          <MenuItem value="pt">Portugal</MenuItem>
          <MenuItem value="nl">Dutch</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
          <MenuItem value="th">Thailand</MenuItem>
          <MenuItem value="fr">France</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Page type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Page type"]}
          variant="standard"
          margin="normal"
          value="home"
          onChange={(e) => setTypePage(e.target.value)}
        >
          <MenuItem value="serve">{text["Service"]}</MenuItem>
          <MenuItem value="home">{text["Home"]}</MenuItem>
          <MenuItem value="exchange">{text["Exchange"]}</MenuItem>
        </Select>
      </FormControl>
      <Button
        autoFocus
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text["Confirm"]}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Edit" ? (
        <Edit row={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Create" ? (
        <AddFaq row={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Delete" ? (
        <Delete row={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select the status of the request</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [titleDialog, setTitleDialog] = useState("");
  const [change, setChange] = useState(0);

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
    setChange(change + 1);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getFaqs", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [change, curr_user_id]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.news_management} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.Manage}</StyledTableCell>
                  <StyledTableCell>{text.Question}</StyledTableCell>
                  <StyledTableCell>{text["Lang type"]}</StyledTableCell>
                  <StyledTableCell>{text["Page type"]}</StyledTableCell>
                  <StyledTableCell>{text.creation_time}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Edit");
                            setSelectedValue(row);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Edit}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Delete");
                            setSelectedValue(row);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Delete}</ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>{row.question}</TableCell>
                    <TableCell>{row.lang}</TableCell>
                    <TableCell>{row.type_page}</TableCell>
                    <TableCell>
                      {new Date(row.release_time).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={() => {
              setTitleDialog("Create");
              setSelectedValue({});
              handleClickOpen();
            }}
            style={{
                float: "right",
            }}
          >{text.Create}</Button>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default Settings;
