import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Switch from "@mui/material/Switch";
import lang from "../lang/language.json";

const cookies = new Cookies();

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        })
        .catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }

    setData(JSON.parse(localStorage.getItem("data_pool")));
  }, []);

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem("data_pool");
      if (item) {
        setData(JSON.parse(item));
      }
    }

    checkUserData();

    window.addEventListener("storage", checkUserData);

    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const updateData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updatePoolData", {
        id: curr_user_id,
        newData: data,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("data_pool", JSON.stringify(data));
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.mining_pool} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          {data
            ? data.map((item, key) => {
                console.log(data[key].value);
                return (
                  <Stack
                    spacing={2}
                    key={key}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography variant="h6" width={400}>
                      {text[item.field_name]}
                    </Typography>
                    {item.field_name === "Authorization for all new users" ? (
                      <FormControl>
                        <Select
                          id="simple-select-label"
                          required
                          variant="outlined"
                          margin="normal"
                          value={data[key].value}
                          onChange={(e) => {
                            let newData = [...data];
                            newData[key].value = e.target.value;
                            setData(newData);
                          }}
                        >
                          <MenuItem value={1}>{text["Yes"]}</MenuItem>
                          <MenuItem value={0}>{text["No"]}</MenuItem>
                        </Select>
                      </FormControl>
                    ) : item.data_key === "node_activation" ? (
                      <Switch
                        // aria-label="Activate auth with node"
                        //checked={Boolean(parseInt(data[key].value))}
                        defaultChecked={
                          typeof data[key].value === "string"
                            ? Boolean(parseInt(data[key].value))
                            : data[key].value
                        }
                        value={
                          typeof data[key].value === "string"
                            ? Boolean(parseInt(data[key].value))
                            : data[key].value
                        }
                        onChange={(e) => {
                          let newData = [...data];
                          newData[key].value = e.target.checked;
                          setData(newData);
                        }}
                      />
                    ) : item.data_key === "revenue_freq" ? (
                      <FormControl>
                        <Select
                          id="simple-select-label"
                          required
                          variant="outlined"
                          margin="normal"
                          value={parseInt(data[key].value / 3600)}
                          onChange={(e) => {
                            let newData = [...data];
                            newData[key].value = e.target.value * 3600;
                            setData(newData);
                          }}
                        >
                          <MenuItem value={1}>1 h</MenuItem>
                          <MenuItem value={2}>2 h</MenuItem>
                          <MenuItem value={3}>3 h</MenuItem>
                          <MenuItem value={4}>4 h</MenuItem>
                          <MenuItem value={5}>5 h</MenuItem>
                          <MenuItem value={6}>6 h</MenuItem>
                          <MenuItem value={7}>7 h</MenuItem>
                          <MenuItem value={8}>8 h</MenuItem>
                          <MenuItem value={9}>9 h</MenuItem>
                          <MenuItem value={10}>10 h</MenuItem>
                          <MenuItem value={11}>11 h</MenuItem>
                          <MenuItem value={12}>12 h</MenuItem>
                          <MenuItem value={13}>13 h</MenuItem>
                          <MenuItem value={14}>14 h</MenuItem>
                          <MenuItem value={15}>15 h</MenuItem>
                          <MenuItem value={16}>16 h</MenuItem>
                          <MenuItem value={17}>17 h</MenuItem>
                          <MenuItem value={18}>18 h</MenuItem>
                          <MenuItem value={19}>19 h</MenuItem>
                          <MenuItem value={20}>20 h</MenuItem>
                          <MenuItem value={21}>21 h</MenuItem>
                          <MenuItem value={22}>22 h</MenuItem>
                          <MenuItem value={23}>23 h</MenuItem>
                          <MenuItem value={24}>24 h</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={data[key].value}
                        onChange={(e) => {
                          let newData = [...data];
                          newData[key].value = e.target.value;
                          setData(newData);
                        }}
                      />
                    )}
                  </Stack>
                );
              })
            : null}
          <Button variant="contained" onClick={updateData}>
            {text.save}
          </Button>
        </Stack>
      </Content>
    </div>
  );
};

export default Settings;
