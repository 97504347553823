import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, TableHead } from "@mui/material";

import { Container, List, MenuItem, Select } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";

import axios from "axios";
const cookies = new Cookies();

function Edit({ row, close }) {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const [title, setTitle] = useState(row.title);
  const [lang_type, setLangType] = useState(row.lang);
  const [content, setContent] = useState(row.content);

  const save = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updPopup", {
        id: row.id,
        title: title,
        lang_type: lang_type,
        content: content,
      })
      .then((res) => {
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        required
        label={text["Title"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Lang type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Lang type"]}
          variant="standard"
          margin="normal"
          value={lang_type}
          onChange={(e) => setLangType(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="cn">Chinese</MenuItem>
          <MenuItem value="jp">Japan</MenuItem>
          <MenuItem value="es">Spain</MenuItem>
          <MenuItem value="kr">Korean</MenuItem>
          <MenuItem value="vn">Vietnam</MenuItem>
          <MenuItem value="inr">Indonesia</MenuItem>
          <MenuItem value="tr">Turkey</MenuItem>
          <MenuItem value="ru">Russian</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
          <MenuItem value="fr">French</MenuItem>
          <MenuItem value="th">Thai</MenuItem>
          <MenuItem value="pt">Portuguese</MenuItem>
          <MenuItem value="nl">Dutch</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="standard-basic"
        disabled
        value={row.user_id}
        label={text["User id"]}
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        id="standard-basic"
        required
        rows={5}
        label={text["Content"]}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <Button
        autoFocus
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text["save"]}
      </Button>
    </Container>
  );
}

function Delete({ row, close }) {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const del = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/delPopup", { id: row.id })
      .then((res) => {
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container>
      <Button
        autoFocus
        variant="outlined"
        style={{ marginBottom: "10px" }}
        onClick={del}
      >
        {text["Delete"]}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Edit" ? (
        <Edit row={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Delete" ? (
        <Delete row={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const WithdrawalRequests = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [titleDialog, setTitleDialog] = useState("");
  const [change, setChange] = useState(0);

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
    setChange(change + 1);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getPopups", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [change, curr_user_id]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.users_popups} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.Manage}</StyledTableCell>
                  <StyledTableCell>{text.Title}</StyledTableCell>
                  <StyledTableCell>{text["Lang type"]}</StyledTableCell>
                  <StyledTableCell>{text["User id"]}</StyledTableCell>
                  <StyledTableCell>{text.creation_time}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setSelectedValue(row);
                            setTitleDialog("Edit");
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Edit}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setSelectedValue(row);
                            setTitleDialog("Delete");
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Delete}</ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.lang}</TableCell>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>
                      {new Date(row.time_release).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default WithdrawalRequests;
