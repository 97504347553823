import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import { TextField, Typography } from "@mui/material";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import Web3 from "web3";
import abi_usdt from "../abi_usdt.json";
import abi_usdc from "../abi_usdc.json";
import abi_busd from "../abi_busd.json";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import lang from "../lang/language.json";
import axios from "axios";
const cookies = new Cookies();

const TransferFunds = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [address, setAddress] = useState("");
  const [address_from, setAddress_from] = useState("");
  const [address_to, setAddress_to] = useState("");
  const [amount, setAmount] = useState(0);
  const [coin, setCoin] = useState();
  const [contract, setContract] = useState();
  const [tronWeb, setTronWeb] = useState();
  const [open2, setOpen2] = useState(false);
  const [error, setError] = useState("");
  const [config, setConfig] = useState({
    name: "usdt",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimals: 6,
    abi: abi_usdt,
    function: "transferFrom",
  });
  const [web3, setWeb3] = useState();

  const [web3Modal, setWeb3Modal] = useState(null);

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  /* async function getTronWeb() {
    let tronWeb;
    if (window.tronLink.ready) {
      tronWeb = tronLink.tronWeb;
    } else {
      const res = await tronLink.request({ method: 'tron_requestAccounts' });
      if (res.code === 200) {
        tronWeb = tronLink.tronWeb;
      }
    }
    return tronWeb;
  } */

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    const providerOptions = {};

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true,
      network: "mainnet",
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  async function connectWallet() {
    if (!coin) {
      alert(text["Please select a coin"]);
      return;
    }

    if (
      coin === "usdt erc20" ||
      coin === "usdc erc20" ||
      coin === "usdt bep20"
    ) {
      const providerOptions = {};

      const newWeb3Modal = new Web3Modal({
        cacheProvider: true,
        network: "mainnet",
        providerOptions,
      });

      if (newWeb3Modal) {
        const provider = await newWeb3Modal.connect();

        const web3 = new Web3(provider);
        setWeb3(web3);

        const ethersProvider = new providers.Web3Provider(provider);
        const userAddress = await ethersProvider.getSigner().getAddress();

        addListeners(provider);
        setAddress(userAddress);
      }
    } else if (coin === "usdt trc20") {
      if (window.tronWeb) {
        const tronWeb = window.tronWeb;
        console.log(tronWeb.defaultAddress.base58);
        setTronWeb(tronWeb);
        /* window.tronWeb
          .request({ method: "tron_requestAccounts" })
          .then((res) => {
            if (res.code === 200) {
              setTronWeb(window.tronWeb);
            }
          }); */
      } else {
        alert("Please install TronLink");
      }
    }
  }

  async function addListeners(web3ModalProvider) {
    web3ModalProvider.on("accountsChanged", (accounts) => {
      window.location.reload();
    });

    // Subscribe to chainId change
    web3ModalProvider.on("chainChanged", (chainId) => {
      window.location.reload();
    });
  }

  /* useEffect(() => {
    // connect automatically and without a popup if user is already connected
    if (web3Modal && web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal]); */

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (coin && address) {
      if (coin === "usdt erc20" && address.startsWith("0x")) {
        web3.eth.getChainId().then(async (e) => {
          if (e !== 1) {
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x1" }],
            });
            return;
          }
        });
        setConfig({
          name: "usdt",
          address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
          decimals: 6,
          abi: abi_usdt,
          function: "transferFrom",
        });
      } else if (coin === "usdc erc20" && address.startsWith("0x")) {
        web3.eth.getChainId().then(async (e) => {
          if (e !== 1) {
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x1" }],
            });
            return;
          }
        });
        setConfig({
          name: "usdc",
          address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
          decimals: 6,
          abi: abi_usdc,
          function: "transferFrom",
        });
      } else if (coin === "usdt bep20" && address.startsWith("0x")) {
        web3.eth.getChainId().then(async (e) => {
          if (e !== 56) {
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            return;
          }
        });

        setConfig({
          name: "usdt bep20",
          address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
          decimals: 18,
          abi: abi_busd,
          function: "transferFrom",
        });
      } else if (coin === "usdt trc20" && address.startsWith("T")) {
        setConfig({
          name: "usdt",
          address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
          decimals: 6,
          abi: tronWeb.contract().at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t").abi,
          function: "transferFrom",
        });
      }
    }
  }, [address, coin]);

  useEffect(() => {
    if (tronWeb) {
      setAddress(tronWeb.defaultAddress.base58);
    }
  }, [tronWeb]);

  const transfer = async () => {
    /* if (!web3) {
      alert("Please connect your wallet");
      return;
    } */

    if (!coin) {
      alert(text["Please select a coin"]);
      return;
    }

    if (address_to === "") {
      alert(text["Please enter the address to transfer to"]);
      return;
    }

    if (amount <= 0) {
      alert(text["Please enter the amount to transfer"]);
      return;
    }

    if (address_from === "") {
      alert(text["Please enter the address to transfer from"]);
      return;
    }

    if (coin !== "usdt trc20") {
      const contract = new web3.eth.Contract(config.abi, config.address);
      setContract(contract);
      const decimals = config.decimals;

      contract.methods[config.function](
        address_from,
        address_to,
        ethers.BigNumber.from(amount).mul(
          ethers.BigNumber.from(10).pow(decimals)
        )
      ).send(
        {
          from: address,
        },
        (err, transactionHash) => {
          if (err) {
            console.log(err);
            setError(text["Something went wrong, error"] + ": " + err);
            setOpen2(true);
          } else {
            console.log(transactionHash);
            setError(text["Transaction sent, hash"] + ": " + transactionHash);
            setOpen2(true);

            axios
              .post(process.env.REACT_APP_API_MAIN + "/addTrunsfer", {
                address_from: address_from,
                address_to: address_to,
                amount: amount,
                type: coin,
                hash: transactionHash,
                domain: process.env.REACT_APP_SITE_DOMAIN,
              })
              .then((res) => {
                console.log(res);
              });
          }
        }
      );
    } else {
      const contract = await tronWeb.contract().at(config.address);
      setContract(contract);
      const decimals = config.decimals;

      try {
        const res = contract[config.function](
          address_from,
          address_to,
          tronWeb.toSun(amount)
        ).send(
          {
            from: address,
          },
          (err, transactionHash) => {
            if (err) {
              console.log(err);
              setError(text["Something went wrong, error"] + ": " + err);
              setOpen2(true);
            } else {
              console.log(transactionHash);
              setError(text["Transaction sent, hash"] + ": " + transactionHash);
              setOpen2(true);

              axios
                .post(process.env.REACT_APP_API_MAIN + "/addTrunsfer", {
                  address_from: address_from,
                  address_to: address_to,
                  amount: amount,
                  type: coin,
                  hash: transactionHash,
                  domain: process.env.REACT_APP_SITE_DOMAIN,
                })
                .then((res) => {
                  console.log(res);
                });
            }
          }
        );
      } catch (e) {
        console.log(e);
        setError(text["Something went wrong, error"] + ": " + e);
        setOpen2(true);
      }
    }
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.transfer_funds} />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open2}
        autoHideDuration={3000}
        onClose={handleClose}
        message={error}
        action={action}
      />
      <Content open={open}>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="primary"
            onClick={connectWallet}
            style={{ marginBottom: "20px" }}
          >
            {text["Connect Wallet"]}
          </Button>
          <Typography variant="h6" gutterBottom component="div">
            {address}
          </Typography>
        </Stack>
        <Stack spacing={2} direction="column">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={["usdt erc20", "usdc erc20", "usdt bep20", "usdt trc20"]}
            fullWidth
            onChange={(event, newValue) => {
              newValue ? setCoin(newValue) : setCoin("");
            }}
            renderInput={(params) => (
              <TextField {...params} label={text["Select coin"]} />
            )}
          />
          {
            // if wallet is connected
            address ? (
              <>
                <TextField
                  label={text["Address From"]}
                  variant="outlined"
                  value={address_from}
                  onChange={(e) => setAddress_from(e.target.value)}
                />
                <TextField
                  label={text["Address To"]}
                  variant="outlined"
                  value={address_to}
                  onChange={(e) => setAddress_to(e.target.value)}
                />
                <TextField
                  label={text["Amount"]}
                  variant="outlined"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Button variant="contained" onClick={transfer}>
                  {text["Transfer"]}
                </Button>
              </>
            ) : (
              <Typography variant="h6" gutterBottom component="div">
                {text["Please connect your wallet"]}
              </Typography>
            )
          }
        </Stack>
      </Content>
    </div>
  );
};

export default TransferFunds;
