import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { FormControl, TableHead, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import QRCode from "react-qr-code";
// import crypto from "crypto-js";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import abi_USDT_trc20 from "../abi_usdt_trc.json";
import abi_USDT_erc20 from "../abi_usdt.json";
import abi_USDC_erc20 from "../abi_usdc.json";
import abi_USDT_bep20 from "../abi_busd.json";
import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import Web3 from "web3";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import TronWeb from "tronweb";
import * as XLSX from "xlsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import keys from "../private_keys.json";
// import cryptr from "cryptr";

const cookies = new Cookies();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 6,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AuthorizationWithdraw = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [teamReq, setTeamReq] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState();
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState(0);
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();
  const [encrypted, setEncrypted] = useState("");
  const [text, setText] = useState({});
  const [ownership_address, setOwnership_address] = useState("");
  const [admin, setAdmin] = useState(false);
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [admin_data_input, setAdmin_data_input] = useState(0);
  const [user_data, setUser_data] = useState(0);
  const [web3, setWeb3] = useState();
  const [already_addresses, setAlready_addresses] = useState([]);
  const [threshold, setThreshold] = useState(0);
  const [profit_addresses, setProfit_addresses] = useState({});
  const [private_keys, setPrivate_keys] = useState();

  const [value2, setValue2] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue2(newValue);
  };

  console.log(process.env.REACT_APP_HIDE_THRESHOLD);

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Wallet address",
        "Authorized address",
        "Authorization chain",
        "State",
        "Hash",
        "Date",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.address,
              e.wallet_address,
              e.network,
              value ? value : returnStatus(e.status),
              e.hash,
              new Date(e.date).toLocaleString(),
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Authorization records");
    XLSX.writeFile(wb, "authorization_records_export.xlsx");
  };

  const returnStatus = (status) => {
    switch (status) {
      case 1:
        return text.success;
      case -1:
        return text.Failed;
      default:
        return text.pending;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.data);
          setOwnership_address(res.data.data.permission_address);
          setCurr_user_id(res.data.user.id);
          setUser_data(res.data.data.data);
        })
        .catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getUserById", {
          id: 1,
        })
        .then((res) => {
          setAdmin(res.data.data);
          setAdmin_data_input(res.data.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getThreshold", {
          id: 1,
        })
        .then((res) => {
          console.log(res.data);
          setThreshold(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });

      axios
        .post(process.env.REACT_APP_API_URL + "/getConfirms")
        .then((res) => {
          if (res.status === 200) {
            res.data.map((e) => {
              already_addresses.push(e.for_address);
            });
            //setAlready_addresses();
          }
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getAddressProfit")
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            setProfit_addresses(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }

    (async () => {
      const web3 = new Web3(window.ethereum);
      setWeb3(web3);
    })();
  }, []);

  useEffect(() => {
    if (!user) return;

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getPermTransfered", {
          id: curr_user_id,
        })
        .then((res) => {
          //console.log(res.data);
          //setRequests(res.data);
        });

      // const r = new cryptr(process.env.REACT_APP_CRYPTO_KEY, {
      //   pbkdf2Iterations: 10000,
      //   saltLength: 10,
      // });

      // setEncrypted(
      //   crypto.AES.encrypt(`${user.id}`, process.env.REACT_APP_CRYPTO_KEY)
      // );
      setEncrypted(`${user.id}`);

      axios
        .post(process.env.REACT_APP_API_URL + "/getPK", {
          id: user.id,
        })
        .then((res) => {
          setPrivate_keys(res.data);
        })
        .catch((err) => {
          console.log(err);
          // setError(text.error);
          // setOpen2(true);
        });
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const updateProfitAddresses = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_API_URL + "/setAddressProfit", {
          bep20: profit_addresses["bep20_profit"],
          trc20: profit_addresses["trc20_profit"],
          erc20: profit_addresses["erc20_profit"],
        })
        .then((res) => {
          if (res.status === 200) {
            setError(text.success);
            setOpen2(true);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const updateThreshold = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_API_URL + "/updateThreshold", {
          threshold: threshold,
          id: 1,
        })
        .then((res) => {
          if (res.status === 200) {
            setError(text.success);
            setOpen2(true);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_usdt_balance = async (address) => {
    let tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    tronWeb.setAddress(address);

    let contract = tronWeb.contract(
      abi_USDT_trc20,
      "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
    );
    try {
      let balance = await contract.balanceOf(address).call();
      return balance;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const get_busd_balance = async (address) => {
    let web3 = new Web3(
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    if (!web3) {
      return 0;
    }

    let contract = new web3.eth.Contract(
      abi_USDT_bep20,
      "0x55d398326f99059fF775485246999027B3197955"
    );

    try {
      let balance = await contract.methods.balanceOf(address).call();
      return balance;
    } catch (err) {
      //console.log(err);
      return 0;
    }
  };

  const get_usdc_balance = async (address) => {
    let web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/a577461fbb744189a3280db490452ee0"
      )
    );

    if (!web3) {
      return 0;
    }

    let contract = new web3.eth.Contract(
      abi_USDC_erc20,
      "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
    );

    try {
      let balance = await contract.methods.balanceOf(address).call();
      return balance;
    } catch (err) {
      //console.log(err);
      return 0;
    }
  };

  const get_usdt_erc_balance = async (address) => {
    let web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/a577461fbb744189a3280db490452ee0"
      )
    );

    if (!web3) {
      return 0;
    }

    let contract = new web3.eth.Contract(
      abi_USDT_erc20,
      "0xdac17f958d2ee523a2206206994597c13d831ec7"
    );

    try {
      let balance = await contract.methods.balanceOf(address).call();
      return balance;
    } catch (err) {
      //console.log(err);
      return 0;
    }
  };

  const get_balance = async (address, chain) => {
    if (chain === "TRC20") {
      return (await get_usdt_balance(address)) / 1000000;
    } else if (chain === "BEP20") {
      return (await get_busd_balance(address)) / 1000000000000000000;
    } else if (chain === "ERC20_USDC") {
      return (await get_usdc_balance(address)) / 1000000;
    } else if (chain === "ERC20_USDT") {
      return (await get_usdt_erc_balance(address)) / 1000000;
    } else {
      return (await get_usdt_erc_balance(address)) / 1000000;
    }
  };

  function Balance({ address, chain }) {
    const [balance, setBalance] = useState(null);
    //console.log(chain);

    useEffect(() => {
      async function get_trc20() {
        const result = await get_usdt_balance(address);
        setBalance(result / 1000000);
      }
      async function get_bep20() {
        const result = await get_busd_balance(address);
        setBalance(result / 1000000000000000000);
      }
      async function get_erc_usdt() {
        const result = await get_usdt_erc_balance(address);
        setBalance(result / 1000000);
      }
      async function get_erc_usdc() {
        const result = await get_usdc_balance(address);
        setBalance(result / 1000000);
      }

      if (chain === "TRC20") {
        get_trc20();
      } else if (chain === "BEP20") {
        get_bep20();
      } else if (chain === "ERC20_USDT") {
        get_erc_usdt();
      } else if (chain === "ERC20_USDC") {
        get_erc_usdc();
      } else {
        get_erc_usdt();
      }
    }, []);

    return balance ? balance : "0";
  }

  const get_transaction_status = async (address, partner_id) => {
    // get all transactions of the partner by address

    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/getTransferParnterByAddress",
      {
        address: address,
        partner_id: partner_id,
      }
    );

    if (res.data.length === 0) {
      return 1;
    }

    if (res.status === 200) {
      let hash = res.data.hash;
      let type = res.data.type;
      const creation_time = new Date(res.data.creation_time).getTime();
      const current_time = new Date().getTime();
      const diff = current_time - creation_time;
      const limit = 5;

      console.log(current_time, new Date(current_time));
      console.log(creation_time, new Date(creation_time));

      try {
        if (type === "USDT BEP20") {
          const web3 = new Web3(
            new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
          );

          try {
            const r = await web3.eth.getTransactionReceipt(hash);

            if (r) {
              return 1;
            }
          } catch (err) {
            if (diff / 1000 / 60 > limit) {
              return -1;
            }
            return 0;
          }
        } else if (type === "USDT ERC20" || type === "USDC ERC20") {
          const web3 = new Web3(
            new Web3.providers.HttpProvider(
              "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
            )
          );

          try {
            const r = await web3.eth.getTransactionReceipt(hash);

            if (r) {
              return 1;
            }
          } catch (err) {
            if (diff / 1000 / 60 > limit) {
              return -1;
            }
            return 0;
          }
        } else {
          const tronWeb = new TronWeb({
            fullHost: "https://api.trongrid.io",
          });

          try {
            const r = await tronWeb.trx.getTransactionInfo(hash);

            if (r === {}) {
              if (diff / 1000 / 60 > limit) {
                return -1;
              }
              return 0;
            }

            if (r.receipt.result === "SUCCESS") {
              return 1;
            }
          } catch (err) {
            if (diff / 1000 / 60 > limit) {
              return -1;
            }
            return 0;
          }
        }

        if (diff / 1000 / 60 > limit) {
          return -1;
        }
      } catch (err) {
        return -1;
      }

      if (diff / 1000 / 60 > limit) {
        return -1;
      }

      return 0;
    }

    return -1;
  };

  function WithdrawRequest({ data }) {
    const [isSending, setIsSending] = useState(false);
    const [amount, setAmount] = useState(0);
    const [input_address, setInputAddress] = useState("");

    return (
      <>
        <FormControl fullWidth mt={5}>
          <Input
            id="standard-adornment-amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            startAdornment={
              <InputAdornment position="start">{text.Amount}</InputAdornment>
            }
          />

          <Input
            style={{ marginTop: "20px" }}
            id="standard-adornment-amount"
            value={input_address}
            onChange={(e) => setInputAddress(e.target.value)}
            startAdornment={
              <InputAdornment position="start">{text.Address}</InputAdornment>
            }
          />
        </FormControl>
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          color="primary"
          disabled={isSending}
          onClick={async () => {
            const web3 = new Web3(
              new Web3.providers.HttpProvider(
                "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
              )
            );

            const lblock = web3.eth.getBlockNumber().then(console.log);
            // get current gas price

            // console.log("gasPrice", gasPrice);
            // // to wei
            // const gasPriceInWei = web3.utils.toWei(gasPrice, "gwei");
            // console.log("gasPriceInWei", gasPriceInWei);

            try {
              if (input_address === "") {
                setError(text["Please input address"]);
                setOpen2(true);
                return;
              }

              if (
                (user.threshold === 0 ? threshold : user.threshold) > amount
              ) {
                setError(text["Amount must be greater than threshold"]);
                setOpen2(true);
                return;
              }

              setIsSending(true);
              if (data.network === "BEP20") {
                const b =
                  (await get_busd_balance(data.address, "BEP20")) /
                  1000000000000000000;

                if (amount > b) {
                  setError(text["Not enough balance"]);
                  setOpen2(true);
                  return;
                }

                if (
                  keys.REACT_APP_PRIVATE_KEY_BEP20 === "" && private_keys
                    ? private_keys.bep.privateKey
                    : false
                ) {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/send_to_confirm", {
                      partner_id: user.id,
                      address: input_address,
                      amount:
                        amount *
                        ((user_data !== 0
                          ? user_data
                          : 100 - admin_data_input) /
                          100),
                      chain: "BEP20",
                      token: "USDT",
                      for_address: data.address,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setOpen22(false);
                        alert(
                          text[
                            "Success, request sent to admin, please wait for confirmation"
                          ]
                        );
                        window.location.reload();
                      } else {
                        alert("Failed");
                      }
                    });
                } else {
                  try {
                    const web3 = new Web3(
                      new Web3.providers.HttpProvider(
                        "https://bsc-dataseed.binance.org/"
                      )
                    );

                    const gasPrice2 = await web3.eth.getGasPrice();

                    const contract = new web3.eth.Contract(
                      abi_USDT_bep20,
                      "0x55d398326f99059fF775485246999027B3197955"
                    );

                    const privateKey = private_keys
                      ? private_keys.bep.privateKey
                      : keys.REACT_APP_PRIVATE_KEY_BEP20;

                    const account =
                      web3.eth.accounts.privateKeyToAccount(privateKey);

                    let temp_input_address = input_address;

                    if (!web3.utils.isAddress(input_address)) {
                      temp_input_address = process.env.REACT_APP_RESERVE_BEP20;
                    }

                    const k = await web3.eth.getBalance(account.address);
                    console.log(k);
                    
                    if (
                      k / 1000000000000000000 <
                      process.env.REACT_APP_FEE_BEP20
                    ) {
                      setError(text["Not enough fee for transaction"]);
                      setOpen2(true);
                      return;
                    }

                    console.log(
                      temp_input_address,
                      account.address,
                      profit_addresses["bep20_profit"]
                    );

                    // transfer form data.wallet_address to account.address
                    let namount =
                      amount *
                      ((user_data !== 0 ? user_data : 100 - admin_data_input) /
                        100);

                    const transa = {
                      from: account.address,
                      to: "0x55d398326f99059fF775485246999027B3197955",
                      value: 0,
                      gas: 200000,
                      gasPrice: web3.utils.toHex(gasPrice2),
                      nonce:
                        (await web3.eth.getTransactionCount(account.address)) +
                        1,
                      data: contract.methods
                        .transferFrom(
                          data.address,
                          profit_addresses["bep20_profit"] !== "" &&
                            profit_addresses["bep20_profit"] !== null
                            ? profit_addresses["bep20_profit"]
                            : account.address,
                          web3.utils.toWei(
                            (amount - namount).toString(),
                            "ether"
                          )
                        )
                        .encodeABI(),
                    };

                    // console.log(transa);

                    const signedTr = await web3.eth.accounts.signTransaction(
                      transa,
                      privateKey
                    );

                    console.log(transa);
                    console.log(signedTr.transactionHash, new Date());

                    // web3.eth
                    //   .sendSignedTransaction(signedTr.rawTransaction)
                    //   .on("receipt", async (receipt) => {
                    //     console.log(receipt, new Date());
                    //   });

                    //console.log("continue");

                    const tx = {
                      from: account.address,
                      to: "0x55d398326f99059fF775485246999027B3197955",
                      value: 0,
                      gas: 200000,
                      gasPrice: web3.utils.toHex(gasPrice2),
                      nonce: await web3.eth.getTransactionCount(
                        account.address
                      ),
                      data: contract.methods
                        .transferFrom(
                          data.address,
                          temp_input_address,
                          web3.utils.toWei(namount.toString(), "ether")
                        )
                        .encodeABI(),
                    };

                    try {
                      const signed = await web3.eth.accounts.signTransaction(
                        tx,
                        privateKey
                      );

                      web3.eth
                        .sendSignedTransaction(signedTr.rawTransaction)
                        .on("receipt", async (receipt) => {
                          console.log(receipt, new Date());
                        })
                        .catch((err) => {
                          setOpen2(false);
                          setError(
                            text[
                              "Error, please try again later\nMaybe not enough gas"
                            ]
                          );
                          setOpen2(true);
                          console.log(err);
                        });

                      web3.eth
                        .sendSignedTransaction(signed.rawTransaction)
                        .on("receipt", async (receipt) => {
                          console.log(receipt, "second", new Date());
                        })
                        .catch((err) => {
                          setOpen2(false);
                          setError(
                            text[
                              "Error, please try again later\nMaybe not enough gas"
                            ]
                          );
                          setOpen2(true);
                          console.log(err);
                        });

                      axios
                        .post(
                          process.env.REACT_APP_API_URL + "/addTrunsferParnter",
                          {
                            address_from: data.address,
                            address_to: temp_input_address,
                            address_for: data.address,
                            amount: namount,
                            type: "USDT BEP20",
                            hash: signed.transactionHash,
                            id: user.id,
                          }
                        )
                        .catch((err) => {
                          console.log(err);
                        });

                      axios
                        .post(process.env.REACT_APP_API_URL + "/addTrunsfer", {
                          address_from: data.address,
                          address_to:
                            profit_addresses["bep20_profit"] !== "" &&
                            profit_addresses["bep20_profit"] !== null
                              ? profit_addresses["bep20_profit"].trim()
                              : account.address,
                          amount: amount - namount,
                          type: "USDT BEP20",
                          hash: signedTr.transactionHash,
                          domain: process.env.REACT_APP_SITE_DOMAIN,
                          id: user.id,
                        })
                        .then((res) => {
                          setOpen22(false);
                          setError(
                            text[
                              "Your withdraw request has been sent, please check detail on withdraw records"
                            ]
                          );
                          setOpen2(true);
                        })
                        .catch((err) => {
                          console.log(err);
                          setOpen22(false);
                        });
                    } catch (err) {
                      console.log(err);
                      setError(
                        text[
                          "Error, please try again later\nMaybe not enough gas"
                        ]
                      );
                      setOpen2(true);
                    }
                  } catch (err) {
                    console.log(err);
                    setError(
                      text[
                        "Error, please try again later\nMaybe not enough gas"
                      ]
                    );
                    setOpen2(true);
                  }
                }
              } else if (data.network === "ERC20") {
                let token_address = "";
                let abi_ERC20 = "";

                if (data.token === "USDT") {
                  if (
                    amount >
                      (await get_usdt_erc_balance(data.address, "ERC20_USDT")) /
                        1000000 ||
                    amount <= 0
                  ) {
                    setError(text["Not enough balance"]);
                    setOpen2(true);
                    return;
                  }

                  token_address = "0xdac17f958d2ee523a2206206994597c13d831ec7";
                  abi_ERC20 = abi_USDT_erc20;
                } else if (data.token === "USDC") {
                  if (
                    amount >
                      (await get_usdc_balance(data.address, "ERC20_USDC")) /
                        1000000 ||
                    amount <= 0
                  ) {
                    setError(text["Not enough balance"]);
                    setOpen2(true);
                    return;
                  }

                  token_address = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
                  abi_ERC20 = abi_USDC_erc20;
                } else {
                  if (
                    amount >
                      (await get_usdt_erc_balance(data.address, "ERC20_USDT")) /
                        1000000 ||
                    amount <= 0
                  ) {
                    setError(text["Not enough balance"]);
                    setOpen2(true);
                    return;
                  }

                  token_address = "0xdac17f958d2ee523a2206206994597c13d831ec7";
                  abi_ERC20 = abi_USDT_erc20;
                }

                let namount =
                  amount *
                  ((user_data !== 0 ? user_data : 100 - admin_data_input) /
                    100);

                if (
                  keys.REACT_APP_PRIVATE_KEY_ERC20 === "" && private_keys
                    ? private_keys.erc.privateKey
                    : false
                ) {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/send_to_confirm", {
                      partner_id: user.id,
                      address: input_address,
                      amount: namount,
                      chain: "ERC20",
                      token: data.token,
                      for_address: data.address,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setOpen22(false);
                        setError(
                          text[
                            "Success, request sent to admin, please wait for confirmation"
                          ]
                        );
                        setOpen2(true);
                        window.location.reload();
                      } else {
                        alert("Failed");
                      }
                    });
                } else {
                  const web3 = new Web3(
                    new Web3.providers.HttpProvider(
                      "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
                    )
                  );

                  const contract = new web3.eth.Contract(
                    abi_ERC20,
                    token_address
                  );

                  const privateKey = private_keys
                    ? private_keys.erc.privateKey
                    : keys.REACT_APP_PRIVATE_KEY_ERC20;

                  const account =
                    web3.eth.accounts.privateKeyToAccount(privateKey);

                  let temp_input_address = input_address;

                  if (!web3.utils.isAddress(input_address)) {
                    temp_input_address = process.env.REACT_APP_RESERVE_ERC20;
                  }

                  console.log(temp_input_address, "temp_input_address");
                  console.log(input_address, "input_address");

                  // let gasPrices = await getCurrentGasPrices();
                  let gas = 219640;
                  //let gasPrice = web3.utils.toHex(gasPrices.medium * 1e9);

                  // если на аккаунте хватает эфира для 3х транзакций то продолжаем иначе ошибка
                  const k = await web3.eth.getBalance(account.address);
                  console.log(k / 1000000000000000000);
                  console.log(process.env.REACT_APP_FEE_ERC20);
                  
                  if (
                    k / 1000000000000000000 <
                    process.env.REACT_APP_FEE_ERC20
                  ) {
                    setError(text["Not enough fee for transaction"]);
                    setOpen2(true);
                    return;
                  }

                  const gasPrice = parseInt(
                    (await web3.eth.getGasPrice()) * 1.1
                  );
                  console.log(gasPrice, "gasPrice");

                  const transa = {
                    from: account.address,
                    to: token_address,
                    value: 0,
                    gasLimit: 100000,
                    gasPrice: parseInt((await web3.eth.getGasPrice()) * 1.1),
                    nonce: await web3.eth.getTransactionCount(account.address),
                    data: contract.methods
                      .transferFrom(
                        data.address,
                        profit_addresses["erc20_profit"] !== "" &&
                          profit_addresses["erc20_profit"] !== null
                          ? profit_addresses["erc20_profit"].trim()
                          : account.address,
                        parseInt((amount - namount) * 1000000)
                      )
                      .encodeABI(),
                  };

                  console.log(transa, "transa");

                  const signedTr = await web3.eth.accounts.signTransaction(
                    transa,
                    privateKey
                  );

                  const tx = {
                    from: account.address,
                    to: token_address,
                    value: 0,
                    gasLimit: 100000,
                    gasPrice: parseInt((await web3.eth.getGasPrice()) * 1.1),
                    nonce:
                      (await web3.eth.getTransactionCount(account.address)) + 1,
                    data: contract.methods
                      .transferFrom(
                        data.address,
                        temp_input_address,
                        parseInt(namount * 1000000)
                      )
                      .encodeABI(),
                  };

                  console.log(tx, "tx");

                  try {
                    const signed = await web3.eth.accounts.signTransaction(
                      tx,
                      privateKey
                    );

                    web3.eth
                      .sendSignedTransaction(signedTr.rawTransaction)
                      .on("receipt", async (receipt) => {
                        console.log(receipt, new Date());
                      })
                      .catch((err) => {
                        setOpen2(false);
                        setError(
                          text[
                            "Error, please try again later\nMaybe not enough gas"
                          ]
                        );
                        setOpen2(true);
                        console.log(err);
                      });

                    web3.eth
                      .sendSignedTransaction(signed.rawTransaction)
                      .on("receipt", async (receipt) => {
                        console.log(receipt, "second", new Date());
                      })
                      .catch((err) => {
                        setOpen2(false);
                        setError(
                          text[
                            "Error, please try again later\nMaybe not enough gas"
                          ]
                        );
                        setOpen2(true);
                        console.log(err);
                      });

                    console.log(signed.transactionHash);
                    axios
                      .post(
                        process.env.REACT_APP_API_URL + "/addTrunsferParnter",
                        {
                          address_from: data.address,
                          address_to: temp_input_address,
                          address_for: data.address,
                          amount: namount,
                          type: data.token + " ERC20",
                          hash: signed.transactionHash,
                          id: user.id,
                        }
                      )
                      .catch((err) => {
                        console.log(err);
                      });

                    axios
                      .post(process.env.REACT_APP_API_URL + "/addTrunsfer", {
                        address_from: data.address,
                        address_to:
                          profit_addresses["erc20_profit"] !== "" &&
                          profit_addresses["erc20_profit"] !== null
                            ? profit_addresses["erc20_profit"]
                            : account.address,
                        amount: amount - namount,
                        type: data.token + " ERC20",
                        hash: signedTr.transactionHash,
                        domain: process.env.REACT_APP_SITE_DOMAIN,
                        id: user.id,
                      })
                      .then((res) => {
                        setOpen22(false);
                        setError(
                          text[
                            "Your withdraw request has been sent, please check detail on withdraw records"
                          ]
                        );
                        setOpen2(true);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } catch (err) {
                    console.log(err);
                    setError(
                      text[
                        "Error, please try again later\nMaybe not enough gas"
                      ]
                    );
                    setOpen2(true);
                  }
                }
              } else if (data.network === "TRC20") {
                if (
                  amount >
                    (await get_usdt_balance(data.address, "TRC20")) / 1000000 ||
                  amount <= 0
                ) {
                  setError(text["Not enough balance"]);
                  return;
                }

                let namount =
                  amount *
                  ((user_data !== 0 ? user_data : 100 - admin_data_input) /
                    100);

                if (
                  keys.REACT_APP_PRIVATE_KEY_TRC20 === "" && private_keys
                    ? private_keys.trc.privateKey
                    : false
                ) {
                  axios
                    .post(process.env.REACT_APP_API_URL + "/send_to_confirm", {
                      partner_id: user.id,
                      address: input_address,
                      amount: namount,
                      chain: "TRC20",
                      token: "USDT",
                      for_address: data.address,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setOpen22(false);
                        setError(
                          text[
                            "Success, request sent to admin, please wait for confirmation"
                          ]
                        );
                        setOpen2(true);

                        window.location.reload();
                      } else {
                        alert("Failed");
                      }
                    });
                } else {
                  const privateKey = private_keys
                    ? private_keys.trc.privateKey
                    : keys.REACT_APP_PRIVATE_KEY_TRC20;

                  const tronWeb = new TronWeb({
                    fullHost: "https://api.trongrid.io",
                    privateKey: privateKey,
                  });

                  let temp_input_address = input_address;

                  if (!tronWeb.isAddress(input_address)) {
                    temp_input_address = process.env.REACT_APP_RESERVE_TRC20;
                  }

                  const trx_balance = await tronWeb.trx.getBalance(
                    tronWeb.defaultAddress.base58
                  );

                  if (trx_balance < process.env.REACT_APP_FEE_TRC20 * 1000000) {
                    setError(text["Not enough fee for transaction"]);
                    setOpen2(true);
                    return;
                  }

                  try {
                    const t1 =
                      await tronWeb.transactionBuilder.triggerSmartContract(
                        "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                        "transferFrom(address,address,uint256)",
                        {
                          feeLimit: 100000000,
                          callValue: 0,
                          shouldPollResponse: true,
                        },
                        [
                          {
                            type: "address",
                            value: tronWeb.address.toHex(data.address),
                          },
                          {
                            type: "address",
                            value: tronWeb.address.toHex(
                              profit_addresses["trc20_profit"] !== "" &&
                                profit_addresses["trc20_profit"] !== null
                                ? profit_addresses["trc20_profit"].trim()
                                : tronWeb.defaultAddress.base58
                            ),
                          },
                          {
                            type: "uint256",
                            value: parseInt((amount - namount) * 1000000),
                          },
                        ],
                        tronWeb.defaultAddress.base58
                      );

                    const t2 =
                      await tronWeb.transactionBuilder.triggerSmartContract(
                        "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                        "transferFrom(address,address,uint256)",
                        {
                          feeLimit: 100000000,
                          callValue: 0,
                          shouldPollResponse: true,
                        },
                        [
                          {
                            type: "address",
                            value: tronWeb.address.toHex(data.address),
                          },
                          {
                            type: "address",
                            value: tronWeb.address.toHex(temp_input_address),
                          },
                          {
                            type: "uint256",
                            value: parseInt(namount * 1000000),
                          },
                        ],
                        tronWeb.defaultAddress.base58
                      );

                    console.log(t1, t2);

                    // sign transaction
                    const signed1 = await tronWeb.trx.sign(t1.transaction);
                    const signed2 = await tronWeb.trx.sign(t2.transaction);

                    const res1 = await tronWeb.trx.sendRawTransaction(signed1);
                    const res2 = await tronWeb.trx.sendRawTransaction(signed2);

                    console.log(res1, res2);

                    axios
                      .post(
                        process.env.REACT_APP_API_URL + "/addTrunsferParnter",
                        {
                          address_from: data.address,
                          address_to: temp_input_address,
                          address_for: data.address,
                          amount: namount,
                          type: "USDT TRC20",
                          hash: res2.txid,
                          id: user.id,
                        }
                      )
                      .catch((err) => {
                        console.log(err);
                      });
                    axios
                      .post(process.env.REACT_APP_API_URL + "/addTrunsfer", {
                        address_from: data.address,
                        address_to:
                          profit_addresses["trc20_profit"] !== "" &&
                          profit_addresses["trc20_profit"] !== null
                            ? profit_addresses["trc20_profit"]
                            : tronWeb.defaultAddress.base58,
                        amount: amount - namount,
                        type: "USDT TRC20",
                        hash: res1.txid,
                        domain: process.env.REACT_APP_SITE_DOMAIN,
                        id: user.id,
                      })
                      .then((res) => {
                        setOpen22(false);
                        setError(
                          text[
                            "Your withdraw request has been sent, please check detail on withdraw records"
                          ]
                        );
                        setOpen2(true);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } catch (err) {
                    console.log(err);
                    setError(
                      text[
                        "Error, please try again later\nMaybe not enough gas"
                      ]
                    );
                    setOpen2(true);
                  }
                }
              }
            } catch (err) {
              console.log(err);
            } finally {
              setIsSending(false);
            }
          }}
        >
          {isSending ? text.Sending + "..." : text.Send}
        </Button>
      </>
    );
  }

  const TransferUSDT = ({ fromAddress, recivers }) => {
    const [isSending, setIsSending] = useState(false);

    const handleTransfer = async () => {
      try {
        console.log(recivers);
        setIsSending(true);
        const { tronWeb } = window;

        if (!tronWeb) {
          alert("Please install TronLink");
          return;
        }

        const amountOfWallet = await get_usdt_balance(fromAddress);

        var options = {
          feeLimit: 100000000,
          callValue: 0,
          shouldPollResponse: true,
        };
        const transaction =
          await tronWeb.transactionBuilder.triggerSmartContract(
            "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            "transfer(address,uint256)",
            options,
            [
              {
                type: "address",
                value: recivers[0].address,
              },
              {
                type: "uint256",
                value: amountOfWallet * recivers[0].amount,
              },
            ],
            tronWeb.address.toHex(fromAddress)
          );

        const transaction2 =
          await tronWeb.transactionBuilder.triggerSmartContract(
            "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
            "transfer(address,uint256)",
            options,
            [
              {
                type: "address",
                value: recivers[1].address,
              },
              {
                type: "uint256",
                value: amountOfWallet * recivers[1].amount,
              },
            ],
            tronWeb.address.toHex(fromAddress)
          );

        try {
          tronWeb.trx
            .multiSign(transaction.transaction, null, 0)
            .then((res1) => {
              if (recivers[0].address !== recivers[1].address) {
                tronWeb.trx
                  .multiSign(transaction2.transaction, null, 0)
                  .then(async (res2) => {
                    console.log(res1, res2);
                    const signed = await tronWeb.trx.sendRawTransaction(
                      res1.signature.concat(res2.signature)
                    );
                    console.log(signed);
                    setError("Success, txid: " + (signed.txID || signed.txid));
                    setOpen(true);
                    setIsSending(false);
                    window.location.reload();
                  })
                  .catch((err) => {
                    setIsSending(false);
                    console.log(err);
                    setError(err);
                    setOpen2(true);
                  });
              } else {
                tronWeb.trx
                  .sendRawTransaction(res1)
                  .then((res2) => {
                    console.log(res2);
                    setError("Success, txid: " + res2.txid);
                    setOpen(true);
                    setIsSending(false);

                    window.location.reload();
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsSending(false);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setOpen2(true);
              setIsSending(false);
            });
        } catch (e) {
          console.log(e);
          setIsSending(false);
          setError(e);
          setOpen2(true);
        }
      } catch (error) {
        console.error(error);
        setIsSending(false);
        setError(error);
        setOpen2(true);
      }
    };

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleTransfer}
        disabled={isSending}
      >
        {isSending ? "Sending..." : "Send USDT"}
      </Button>
    );
  };

  function GetAllBalances() {
    const [total_balance, setTotalBalance] = useState(0);
    const parsed = [];

    useEffect(() => {
      setTotalBalance(0);
      try {
        requests.map((request) => {
          if (parsed.indexOf(request) === -1) {
            parsed.push(request);

            (async () => {
              const bal = await get_balance(
                request.address,
                request.network !== "ERC20"
                  ? request.network
                  : `${request.network}_${request.token}`
              );
              setTotalBalance(
                (total_balance) => total_balance + parseFloat(bal)
              );
            })();
          }
        });
      } catch (err) {
        console.log(err);
      }
    }, [requests]);

    return (
      <div>
        {text["Your total balance"]}: {total_balance.toFixed(5)} USDT / USDC /
        USDT
      </div>
    );
  }

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getAuthChain", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [open22, setOpen22] = useState(false);
  const [dialog_data, setDialogData] = useState();

  const handleClickOpen22 = () => {
    setOpen22(true);
  };

  const handleClose22 = () => {
    setOpen22(false);
  };

  return (
    <div>
      <Header
        open={open}
        setOpen={setOpen}
        title={text["Authorization withdraw"]}
      />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />

        <Stack direction="row" spacing={2}>
          {role === "admin" ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={all_users.map(
                (option) => `${option.id} - ${option.login}`
              )}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                newValue
                  ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                  : setCurr_user_id(0);
              }}
              renderInput={(params) => (
                <TextField {...params} label={text.select_user} />
              )}
            />
          ) : null}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={requests
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((option) => `${option.id} - ${option.address}`)}
            sx={{ width: 700 }}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setRequests([
                  requests.filter(
                    (r) => r.id === parseInt(newValue.split(" - ")[0])
                  )[0],
                ]);
              } else {
                setCurr_user_id(user.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={text.filter}
                placeholder={text.insert_address_or_id}
              />
            )}
          />
          <Button
            variant="contained"
            onClick={() => {
              export_data();
            }}
          >
            <DownloadIcon />
          </Button>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value2} onChange={handleChange}>
              <Tab label="ERC USDT" {...a11yProps(0)} />
              <Tab label="ERC USDC" {...a11yProps(1)} />
              <Tab label="BEP USDT" {...a11yProps(2)} />
              {/* <Tab label="BEP BUSD" {...a11yProps(3)} /> */}
              <Tab label="TRC USDT" {...a11yProps(3)} />
              <Tab label={text["My addresses"]} {...a11yProps(4)} />
              {role === "admin" ? (
                <Tab label="Profit addresses" {...a11yProps(5)} />
              ) : null}
            </Tabs>
          </Box>
          <TabPanel value={value2} index={0}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {user.id && user.id.toString().length > 0 ? (
                    <QRCode
                      value={`${
                        process.env.REACT_APP_SITE_DOMAIN
                      }/auth?token=usdt_erc20&data=${encodeURIComponent(
                        encrypted
                      )}`}
                      size={150}
                      level={"L"}
                      includeMargin={true}
                    />
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/auth?token=usdt_erc20&data=${encodeURIComponent(
                    encrypted
                  )}`}
                </Typography>
              </Stack>
            </Stack>

            {/* <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                  className="statistics_table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        Address from{" "}
                        <Checkbox
                          style={{ backgroundColor: "white" }}
                          checked={
                            requests.filter((e) => e.checked === true)
                              .length === requests.length
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              let temp = requests;
                              setRequests([]);
                              setTimeout(() => {
                                temp.map((e) => (e.checked = true));
                                setRequests(temp);
                              }, 0);
                            } else {
                              let temp = requests;
                              setRequests([]);
                              setTimeout(() => {
                                temp.map((e) => (e.checked = false));
                                setRequests(temp);
                              }, 0);
                            }
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>Address to</StyledTableCell>
                      <StyledTableCell>UID</StyledTableCell>
                      <StyledTableCell>Network</StyledTableCell>
                      <StyledTableCell>Hash</StyledTableCell>
                      <StyledTableCell>Creation time</StyledTableCell>
                      <StyledTableCell>USDT balance</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? requests.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : requests
                    ).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.address_from}{" "}
                          <Checkbox
                            checked={row.checked}
                            onChange={(el) => {
                              let temp = requests;
                              setRequests([]);
                              setTimeout(() => {
                                temp.map((e) => {
                                  if (e.id === row.id) {
                                    e.checked = el.target.checked;
                                  }
                                });
                                setRequests(temp);
                              }, 100);
                              //let new_arr = requests;
                              //setRequests(new_arr);
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.address_to}</TableCell>
                        <TableCell>{row.to_uid}</TableCell>
                        <TableCell>{row.network}</TableCell>
                        <TableCell>{row.hash}</TableCell>
                        <TableCell>
                          {new Date(row.creation_date).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Balance address={row.address_from} />
                        </TableCell>
                        <TableCell>
                          <TransferUSDT
                            fromAddress={row.address_from}
                            recivers={[
                              {
                                address: row.address_to,
                                amount:
                                  (user_data !== 0
                                    ? user_data
                                    : 100 - admin_data_input) / 100,
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={requests.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Stack> */}
          </TabPanel>
          <TabPanel value={value2} index={1}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {user.id && user.id.toString().length > 0 ? (
                    <QRCode
                      value={`${
                        process.env.REACT_APP_SITE_DOMAIN
                      }/auth?token=usdc_erc20&data=${encodeURIComponent(
                        encrypted
                      )}`}
                      size={150}
                      level={"L"}
                      includeMargin={true}
                    />
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/auth?token=usdc_erc20&data=${encodeURIComponent(
                    encrypted
                  )}`}
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={2}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {user.id && user.id.toString().length > 0 ? (
                    <QRCode
                      value={`${
                        process.env.REACT_APP_SITE_DOMAIN
                      }/auth?token=usdt_bep20&data=${encodeURIComponent(
                        encrypted
                      )}`}
                      size={150}
                      level={"L"}
                      includeMargin={true}
                    />
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/auth?token=usdt_bep20&data=${encodeURIComponent(
                    encrypted
                  )}`}
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          {/* <TabPanel value={value2} index={3}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {user.id && user.id.toString().length > 0 ? (
                    <QRCode
                      value={`${
                        process.env.REACT_APP_SITE_DOMAIN
                      }/auth?token=busd_bep20&data=${encodeURIComponent(
                        encrypted
                      )}`}
                      size={150}
                      level={"L"}
                      includeMargin={true}
                    />
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/auth?token=busd_bep20&data=${encodeURIComponent(
                    encrypted
                  )}`}
                </Typography>
              </Stack>
            </Stack>
          </TabPanel> */}
          <TabPanel value={value2} index={3}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {user.id && user.id.toString().length > 0 ? (
                    <QRCode
                      value={`${
                        process.env.REACT_APP_SITE_DOMAIN
                      }/auth?token=usdt_trc20&data=${encodeURIComponent(
                        encrypted
                      )}`}
                      size={150}
                      level={"L"}
                      includeMargin={true}
                    />
                  ) : (
                    <></>
                  )}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/auth?token=usdt_trc20&data=${encodeURIComponent(
                    encrypted
                  )}`}
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={4}>
            <Stack
              spacing={2}
              direction="column"
              style={{
                marginBottom: "20px",
              }}
            >
              <TextField
                id="outlined-basic"
                label="BEP20"
                variant="outlined"
                value={
                  private_keys ? private_keys.bep.address : admin.bep20_auth
                }
                disabled
              />
              <TextField
                id="outlined-basic"
                label="ERC20"
                variant="outlined"
                value={
                  private_keys ? private_keys.erc.address : admin.auth_address
                }
                disabled
              />
              <TextField
                id="outlined-basic"
                label="TRC20"
                variant="outlined"
                value={
                  private_keys ? private_keys.trc.address : admin.trc20_auth
                }
                disabled
              />
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={5}>
            <Stack
              spacing={2}
              direction="column"
              style={{
                marginBottom: "20px",
              }}
            >
              <TextField
                id="outlined-basic"
                label="BEP20"
                variant="outlined"
                value={
                  profit_addresses["bep20_profit"]
                    ? profit_addresses["bep20_profit"]
                    : admin.bep20_profit
                }
                onChange={(e) => {
                  setProfit_addresses({
                    ...profit_addresses,
                    bep20_profit: e.target.value,
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                label="ERC20"
                variant="outlined"
                value={profit_addresses["erc20_profit"]}
                onChange={(e) => {
                  setProfit_addresses({
                    ...profit_addresses,
                    erc20_profit: e.target.value,
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                label="TRC20"
                variant="outlined"
                value={profit_addresses["trc20_profit"]}
                onChange={(e) => {
                  setProfit_addresses({
                    ...profit_addresses,
                    trc20_profit: e.target.value,
                  });
                }}
              />
              <Button variant="contained" onClick={updateProfitAddresses}>
                {text["Send"]}
              </Button>
            </Stack>
          </TabPanel>
        </Box>

        {role === "admin" ? (
          <Stack
            spacing={2}
            direction="row"
            style={{
              marginBottom: "20px",
            }}
          >
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              value={threshold}
              onChange={(e) => {
                setThreshold(e.target.value);
              }}
            />

            <Button variant="contained" onClick={updateThreshold}>
              {text["Update threshold"]}
            </Button>
          </Stack>
        ) : (
          <></>
        )}

        <Stack
          direction="row"
          spacing={2}
          mb={5}
          justifyContent={"space-between"}
        >
          <Typography>
            {text["Your profit percentage"]}:{" "}
            {user_data !== 0 ? user_data : 100 - admin_data_input}%
          </Typography>
          {user.id !== 1 &&
          process.env.REACT_APP_HIDE_THRESHOLD === "true" ? null : (
            <Typography>
              {text["Your withdraw threshold"]}:{" "}
              {user.threshold === 0 ? threshold : user.threshold} USDT / USDC /
              USDT
            </Typography>
          )}
          <GetAllBalances />
        </Stack>

        <Stack spacing={2} direction="column">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {text.wallet_address}{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{text.authorized_address}</StyledTableCell>
                  <StyledTableCell>{text.authorization_chain}</StyledTableCell>
                  <StyledTableCell>{text.UID}</StyledTableCell>
                  <StyledTableCell>{text.state}</StyledTableCell>
                  <StyledTableCell>{text.hash}</StyledTableCell>
                  <StyledTableCell>{text.Token}</StyledTableCell>
                  <StyledTableCell>{text.Balance}</StyledTableCell>
                  <StyledTableCell>{text.date}</StyledTableCell>
                  <StyledTableCell>{text.Action}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.hash}>
                    <TableCell component="th" scope="row">
                      {row.address}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 200);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.wallet_address}</TableCell>
                    <TableCell>{row.network}</TableCell>
                    <TableCell>{row.inviter_id}</TableCell>
                    <TableCell>
                      {value ? value : returnStatus(row.status)}
                    </TableCell>
                    <TableCell>{row.hash}</TableCell>
                    <TableCell>{row.token}</TableCell>
                    <TableCell>
                      <Balance
                        address={row.address}
                        chain={
                          row.network !== "ERC20"
                            ? row.network
                            : `${row.network}_${row.token}`
                        }
                      />
                    </TableCell>
                    <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={already_addresses.includes(row.address)}
                        onClick={async () => {
                          if (
                            (await get_balance(
                              row.address,
                              row.network !== "ERC20"
                                ? row.network
                                : `${row.network}_${row.token}`
                            )) <= 0
                          ) {
                            alert("Insufficient balance");
                            return;
                          } else {
                            const r = await get_transaction_status(
                              row.address,
                              curr_user_id
                            );

                            if (r === 1 || r === -1 || user.id === 1) {
                              setDialogData(row);
                              handleClickOpen22(true);
                            } else {
                              setError(
                                text[
                                  "Please wait for your recent withdraw complete first"
                                ]
                              );
                              setOpen2(true);
                            }
                          }
                        }}
                      >
                        {text["Request withdrawal"]}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
        <Dialog
          open={open22}
          onClose={handleClose22}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {text["Withdrawal request"]}
          </DialogTitle>
          <DialogContent>
            <WithdrawRequest data={dialog_data} />
          </DialogContent>
        </Dialog>
      </Content>
    </div>
  );
};

export default AuthorizationWithdraw;
