import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Button,
  Container,
  List,
  MenuItem,
  Select,
  TableHead,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function Presale({ id, close }) {
  const [content_cn, setContentCn] = useState("");
  const [content_en, setContentEn] = useState("");
  const [content_jp, setContentJp] = useState("");
  const [content_es, setContentEs] = useState("");
  const [content_ru, setContentRu] = useState("");
  const [content_kr, setContentKr] = useState("");
  const [content_vn, setContentVn] = useState("");
  const [content_tr, setContentTr] = useState("");
  const [content_inr, setContentInr] = useState("");
  const [content_ar, setContentAr] = useState("");
  const [content_fr, setContentFr] = useState("");
  const [content_th, setContentTh] = useState("");
  const [content_pt, setContentPt] = useState("");
  const [content_nl, setContentNl] = useState("");

  const [cycle, setCycle] = useState(1);
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(0);
  const [expiration, setExpiration] = useState(dayjs(new Date()));

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
    axios
      .post(process.env.REACT_APP_API_URL + "/getPresale", { id: id })
      .then((res) => {
        if (res.status === 200) {
          setContentCn(res.data.cn);
          setContentEn(res.data.en);
          setContentJp(res.data.jp);
          setContentEs(res.data.es);
          setContentRu(res.data.ru);
          setContentKr(res.data.kr);
          setContentVn(res.data.vn);
          setContentTr(res.data.tr);
          setContentInr(res.data.inr);
          setContentAr(res.data.ar);
          setContentFr(res.data.fr);
          setContentTh(res.data.th);
          setContentPt(res.data.pt);
          setContentNl(res.data.nl);
          setCycle(res.data.period);
          setBalance(res.data.request_real_balance);
          setReward(res.data.reward_coin_platform);
          setExpiration(dayjs(res.data.expiration_date));
        }
      });
  }, []);

  const save = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updPresale", {
        id: id,
        cycle: cycle,
        balance: balance,
        reward: reward,
        expiration: dayjs(expiration).format("YYYY-MM-DD HH:mm:ss"),
        content_cn: content_cn,
        content_en: content_en,
        content_jp: content_jp,
        content_es: content_es,
        content_ru: content_ru,
        content_kr: content_kr,
        content_vn: content_vn,
        content_tr: content_tr,
        content_inr: content_inr,
        content_ar: content_ar,
        content_fr: content_fr,
        content_th: content_th,
        content_pt: content_pt,
        content_nl: content_nl,
      })
      .then((res) => {
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        disabled
        label={text["User id"]}
        variant="standard"
        fullWidth
        value={id}
        margin="normal"
      />
      <TextField
        id="standard-basic"
        label={text["Cycle (days)"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={cycle}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 1) {
            setCycle(parseInt(e.target.value));
          } else {
            setCycle(1);
          }
        }}
      />
      <TextField
        id="standard-basic"
        label={text["Request the real balance of the balance address"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={balance}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setBalance(parseInt(e.target.value));
          } else {
            setBalance(0);
          }
        }}
      />
      <TextField
        id="standard-basic"
        label={text["Reward platform currency"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={reward}
        style={{ marginBottom: "30px" }}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setReward(parseInt(e.target.value));
          } else {
            setReward(0);
          }
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label={text["Expiration"]}
          inputFormat="MM/DD/YYYY"
          value={expiration}
          minDate={new Date()}
          onChange={(newValue) => {
            setExpiration(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Chinese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_cn}
        onChange={(e) => setContentCn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (English)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_en}
        onChange={(e) => setContentEn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Japanese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_jp}
        onChange={(e) => setContentJp(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Spanish)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_es}
        onChange={(e) => setContentEs(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Russian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ru}
        onChange={(e) => setContentRu(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Korean)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_kr}
        onChange={(e) => setContentKr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Vietnamese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_vn}
        onChange={(e) => setContentVn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Turkey)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_tr}
        onChange={(e) => setContentTr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Indonesian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_inr}
        onChange={(e) => setContentInr(e.target.value)}
      />

<TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Arabic)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ar}
        onChange={(e) => setContentAr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (French)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_fr}
        onChange={(e) => setContentFr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Thai)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_th}
        onChange={(e) => setContentTh(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Portuguese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_pt}
        onChange={(e) => setContentPt(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Dutch)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_nl}
        onChange={(e) => setContentNl(e.target.value)}
      />

      <Button
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text.save}
      </Button>
    </Container>
  );
}

function Delete({ id, close }) {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const del = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/delPresale", {
        id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container>
      <Button
        autoFocus
        variant="outlined"
        style={{ marginBottom: "10px" }}
        onClick={del}
      >
        {text["Delete"]}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Edit" ? (
        <Presale id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Delete" ? (
        <Delete id={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select the status of the request</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StakingManagement = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [upd, setUpd] = useState(false);

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [titleDialog, setTitleDialog] = useState("");

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const [text, setText] = useState({});

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "ID",
        "User ID",
        "State",
        "Cycle (days)",
        "Request USDT",
        "Award",
        "Expiration date",
        "Add time",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.id,
              e.user_id,
              e.state,
              e.period,
              e.request_real_balance,
              e.reward_coin_platform,
              new Date(e.expiration_date).toLocaleString(),
              new Date(e.creation_date).toLocaleString(),
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Presale Management");
    XLSX.writeFile(wb, "presale_management_export.xlsx");
  };

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
    setUpd(!upd);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/presaleByPartner", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data);

          let arr = [];
          res.data.map((item) => {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].user_id === item.user_id) {
                return;
              }
            }
            arr.push(item);
          });

          setUsers(arr);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id, upd]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.presale_management} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            {role === "admin" ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={all_users.map(
                  (option) => `${option.id} - ${option.login}`
                )}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  newValue
                    ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                    : setCurr_user_id(0);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={text.select_user} />
                )}
              />
            ) : null}

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={users
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((option) => `${option.user_id} - ${option.address}`)}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRequests(
                    requests.filter(
                      (r) => r.user_id === parseInt(newValue.split(" - ")[0])
                    )
                  );
                } else {
                  setCurr_user_id(user.id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.Operate}</StyledTableCell>
                  <StyledTableCell>
                    ID{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>User ID</StyledTableCell>
                  <StyledTableCell>State</StyledTableCell>
                  <StyledTableCell>Cycle (days)</StyledTableCell>
                  <StyledTableCell>Request USDT</StyledTableCell>
                  <StyledTableCell>Award</StyledTableCell>
                  <StyledTableCell>Expiration date</StyledTableCell>
                  <StyledTableCell>Add time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Edit");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Edit}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Delete");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Delete}</ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>

                    <TableCell>
                      {row.id}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.period}</TableCell>
                    <TableCell>{row.request_real_balance}</TableCell>
                    <TableCell>{row.reward_coin_platform}</TableCell>
                    <TableCell>
                      {new Date(row.expiration_date).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.creation_date).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default StakingManagement;
