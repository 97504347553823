import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import RegisterUser from "./pages/RegisterUser";
import WithdrawalRequests from "./pages/WithdrawalRequests";
import UsersManagment from "./pages/UsersManagment";
import AuthorizationRecords from "./pages/AuthorizationRecords";
import Statistics from "./pages/Statistics";
import Popup from "./pages/Popup";
import NewsManagment from "./pages/NewsManagment";
import MiningPool from "./pages/MiningPool";
import MonitoringAddress from "./pages/MonitoringAddress";
import IntegralStatistics from "./pages/IntegralStatistics";
import AddScript from "./pages/AddScript";
import PartnerManagment from "./pages/PartnerManagment";
import TransferFunds from "./pages/TransferFunds";
import ChangeAuthAddress from "./pages/ChangeAuthAddress";
import Transfers from "./pages/Transfers";
import TransfersPartner from "./pages/TransfersPartner";
import PresaleManagment from "./pages/PresaleManagment";
import StakingManagment from "./pages/StakingManagment";
import SocialChange from "./pages/SocialChange";
import Qr from "./pages/Qr";
import AuthorizationWithdraw from "./pages/AuthorizationWithdraw";
import PartnerRequests from "./pages/PartnerRequests";
import SetTronAddress from "./pages/SetTron";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path=":lang/login" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="settings" element={<Settings />} />
          <Route path="register_partner" element={<RegisterUser />} />
          <Route path="withrawal_requests" element={<WithdrawalRequests />} />
          <Route path="users" element={<UsersManagment />} />
          <Route path="monitoring_address" element={<MonitoringAddress />} />
          <Route path="auth_records" element={<AuthorizationRecords />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="popups" element={<Popup />} />
          <Route path="news_management" element={<NewsManagment />} />
          <Route path="integral_statistics" element={<IntegralStatistics />} />
          <Route path="mining_pool_settings" element={<MiningPool />} />
          <Route path="add_script" element={<AddScript />} />
          <Route path="parnters_management" element={<PartnerManagment />} />
          <Route path="transfer_funds" element={<TransferFunds />} />
          <Route path="change_auth" element={<ChangeAuthAddress />} />
          <Route path="transfers" element={<Transfers />} />
          <Route path="transfers_partner" element={<TransfersPartner />} />
          <Route path="presale_management" element={<PresaleManagment />} />
          <Route path="staking_management" element={<StakingManagment />} />
          <Route path="social_change" element={<SocialChange />} />
          <Route path="permission_settings" element={<Qr />} />
          <Route
            path="authorization_withdraw"
            element={<AuthorizationWithdraw />}
          />
          <Route path="partners_requests" element={<PartnerRequests />} />
          <Route path="set_tron_address" element={<SetTronAddress />} />
          <Route path="*" element={<App />} />
          <Route index path="/" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
