import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import Web3 from "web3";
import TronWeb from "tronweb";
import * as XLSX from "xlsx";
const cookies = new Cookies();

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 6,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const WithdrawalRequests = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [teamReq, setTeamReq] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState();
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();

  const [text, setText] = useState({});

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "Address from",
        "Address to",
        "Amount",
        "Network",
        "Hash",
        "Creation time",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.address_from,
              e.address_to,
              e.amount,
              e.type,
              e.hash,
              new Date(e.creation_time).toLocaleString(),
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transfer records");
    XLSX.writeFile(wb, "transfer_records_export.xlsx");
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }

    axios
      .post(process.env.REACT_APP_API_URL + "/getUser", {
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        setUser(res.data.data);
        setCurr_user_id(res.data.user.id);

        axios
          .post(process.env.REACT_APP_API_URL + "/getTransferParnter", {
            id: res.data.user.id,
          })
          .then((res2) => {
            setRequests(res2.data);
          });
      }).catch((err) => {
        cookies.remove("refreshToken");
        navigate("/login");
      });
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  function GetStatusHash({ hash, type, time }) {
    const [status, setStatus] = useState(text.pending);

    useEffect(() => {
      const creation_time = new Date(time).getTime();
      const current_time = new Date().getTime();
      const diff = current_time - creation_time;

      if (type === "USDT BEP20") {
        const web3 = new Web3(
          new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
        );

        web3.eth.getTransactionReceipt(hash).then((res) => {
          if (res) {
            setStatus(text.Completed);
          }

          console.log(res, hash);
        });
      } else if (type === "USDT ERC20" || type === "USDC ERC20") {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(
            "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
          )
        );

        web3.eth.getTransactionReceipt(hash).then((res) => {
          if (res) {
            setStatus(text.Completed);
          }

          console.log(res, hash);
        });
      } else {
        const tronWeb = new TronWeb({
          fullHost: "https://api.trongrid.io",
        });

        tronWeb.trx.getTransactionInfo(hash).then((res) => {
          console.log(res);
          if (res.receipt.result === "SUCCESS" && !res.result) {
            setStatus(text.Completed);
          }
        });
      }


      if (status === text.pending && diff / 1000 / 60 > 5) {
        setStatus(text.Failed);
      }

    }, []);

    return <div>{status}</div>;
  }

  return (
    <div>
      <Header
        open={open}
        setOpen={setOpen}
        title={text["Withdrawal Records"]}
      />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500 }}
              aria-label="custom pagination table"
              className="statistics_table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {text["Address from"]}{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{text["Address to"]}</StyledTableCell>
                  <StyledTableCell>{text["Amount"]}</StyledTableCell>
                  <StyledTableCell>{text["Network"]}</StyledTableCell>
                  <StyledTableCell>{text.hash}</StyledTableCell>
                  <StyledTableCell>{text.Status}</StyledTableCell>
                  <StyledTableCell>{text["creation_time"]}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.address_from}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.address_to}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.hash}</TableCell>
                    <TableCell>
                      {" "}
                      <GetStatusHash hash={row.hash} type={row.type} time={row.creation_time} />{" "}
                    </TableCell>
                    <TableCell>
                      {new Date(row.creation_time).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default WithdrawalRequests;
