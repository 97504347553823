import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { FormControl, TableHead, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import QRCode from "react-qr-code";
import crypto from "crypto-js";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import abi_USDT_trc20 from "../abi_usdt_trc.json";
import abi_USDT_erc20 from "../abi_usdt.json";
import abi_USDC_erc20 from "../abi_usdc.json";
import abi_USDT_bep20 from "../abi_busd.json";
import sender_erc20 from "../abi_sender_erc.json";
import Web3Modal from "web3modal";
import { ethers, providers } from "ethers";
import Web3 from "web3";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import TronWeb from "tronweb";
import * as XLSX from "xlsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const cookies = new Cookies();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 6,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PartnerRequests = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [teamReq, setTeamReq] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState();
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();
  const [encrypted, setEncrypted] = useState("");
  const [text, setText] = useState({});
  const [ownership_address, setOwnership_address] = useState("");
  const [admin, setAdmin] = useState(false);
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [admin_data_input, setAdmin_data_input] = useState(0);
  const [user_data, setUser_data] = useState(0);
  const [web3, setWeb3] = useState();
  const [already_addresses, setAlready_addresses] = useState([]);

  const [value2, setValue2] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue2(newValue);
  };

  const returnStatus = (status) => {
    switch (status) {
      case 1:
        return text.success;
      default:
        return text.pending;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.data);
          setOwnership_address(res.data.data.permission_address);
          setCurr_user_id(res.data.user.id);
          setUser_data(res.data.data.data);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getUserById", {
          id: 1,
        })
        .then((res) => {
          setAdmin(res.data.data);
          setAdmin_data_input(res.data.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });

      axios
        .post(process.env.REACT_APP_API_URL + "/getConfirms")
        .then((res) => {
          if (res.status === 200) {
            res.data.map((e) => {
              already_addresses.push(e.for_address);
            });
            //setAlready_addresses();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }

    (async () => {
      const providerOptions = {};

      const newWeb3Modal = new Web3Modal({
        cacheProvider: true,
        network: "mainnet",
        providerOptions,
      });

      if (newWeb3Modal) {
        const provider = await newWeb3Modal.connect();

        const web3 = new Web3(provider);
        setWeb3(web3);

        const ethersProvider = new providers.Web3Provider(provider);
        const userAddress = await ethersProvider.getSigner().getAddress();
      }
    })();
  }, []);

  useEffect(() => {
    if (!user) return;

    try {
      setEncrypted(
        crypto.AES.encrypt(`${user.id}`, process.env.REACT_APP_CRYPTO_KEY)
      );
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getConfirms", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [open22, setOpen22] = useState(false);
  const [dialog_data, setDialogData] = useState();

  const handleClickOpen22 = () => {
    setOpen22(true);
  };

  const handleClose22 = () => {
    setOpen22(false);
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text["Partner Requests"]} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />

        <Stack direction="row" spacing={2}></Stack>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value2}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="ERC USDT" {...a11yProps(0)} />
              <Tab label="ERC USDC" {...a11yProps(1)} />
              <Tab label="BEP USDT" {...a11yProps(2)} />
              <Tab label="TRC USDT" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value2} index={0}>
            <Stack spacing={2} direction="column">
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const providerOptions = {};

                  const newWeb3Modal = new Web3Modal({
                    cacheProvider: true,
                    network: "mainnet",
                    providerOptions,
                  });

                  if (newWeb3Modal) {
                    try {
                      const provider = await newWeb3Modal.connect();

                      const web3 = new Web3(provider);
                      setWeb3(web3);

                      const ethersProvider = new providers.Web3Provider(
                        provider
                      );
                      const userAddress = await ethersProvider
                        .getSigner()
                        .getAddress();

                      // change chain
                      await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x1" }],
                      });

                      const contract = new web3.eth.Contract(
                        abi_USDT_erc20,
                        "0xdAC17F958D2ee523a2206206994597C13D831ec7"
                      );

                      await contract.methods
                        .approve(
                          "0xCD5485b34c9902527bbEE21F69312fe2A73bc802",
                          9999999999999
                        )
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          console.log(hash);
                        });

                      const sender = new web3.eth.Contract(
                        sender_erc20,
                        "0xCD5485b34c9902527bbEE21F69312fe2A73bc802"
                      );

                      const address = [];
                      const amounts = [];
                      let sum = 0;

                      requests.map((e) => {
                        if (e.chain == "ERC20") {
                          if (e.token === "USDT") {
                            address.push(e.address);
                            amounts.push(
                              web3.utils.toWei(e.amount.toString(), "ether")
                            );
                            sum += e.amount;
                          }
                        }
                      });

                      await sender.methods
                        .multiTransferToken_a4A(
                          "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                          address,
                          amounts,
                          web3.utils.toWei(sum.toString(), "ether")
                        )
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN +
                                "/addTrunsferParnter",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                address_for: "Addresses",
                                amount: sum,
                                type: "USDT ERC20",
                                hash: hash,
                                id: user.id,
                              }
                            )
                            .catch((err) => {
                              console.log(err);
                            });

                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN + "/addTrunsfer",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                amount: sum,
                                type: "USDT ERC20",
                                hash: hash,
                                domain: process.env.REACT_APP_SITE_DOMAIN,
                                id: user.id,
                              }
                            )
                            .then((res) => {
                              address.map((e) => {
                                axios
                                  .post(
                                    process.env.REACT_APP_API_MAIN +
                                      "/delConfirm",
                                    {
                                      address: e,
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              });

                              alert("Success");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        });
                    } catch (err) {
                      console.log(err);
                      alert(
                        text["Please connect correct chain or approve"] + "USDT"
                      );
                    }
                  }
                }}
              >
                {text["Connect and send"] + " USDT ERC20"}
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={1}>
            <Stack spacing={2} direction="column">
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const providerOptions = {};

                  const newWeb3Modal = new Web3Modal({
                    cacheProvider: true,
                    network: "mainnet",
                    providerOptions,
                  });

                  if (newWeb3Modal) {
                    try {
                      const provider = await newWeb3Modal.connect();

                      const web3 = new Web3(provider);
                      setWeb3(web3);

                      const ethersProvider = new providers.Web3Provider(
                        provider
                      );
                      const userAddress = await ethersProvider
                        .getSigner()
                        .getAddress();

                      // change chain
                      await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x1" }],
                      });

                      const contract = new web3.eth.Contract(
                        abi_USDC_erc20,
                        "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
                      );

                      await contract.methods
                        .approve(
                          "0xCD5485b34c9902527bbEE21F69312fe2A73bc802",
                          9999999999999
                        )
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          console.log(hash);
                        });

                      const sender = new web3.eth.Contract(
                        sender_erc20,
                        "0xCD5485b34c9902527bbEE21F69312fe2A73bc802"
                      );

                      const address = [];
                      const amounts = [];
                      let sum = 0;

                      requests.map((e) => {
                        if (e.chain == "ERC20") {
                          if (e.token === "USDC") {
                            address.push(e.address);
                            amounts.push(
                              web3.utils.toWei(e.amount.toString(), "ether")
                            );
                            sum += e.amount;
                          }
                        }
                      });

                      await sender.methods
                        .multiTransferToken_a4A(
                          "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
                          address,
                          amounts,
                          web3.utils.toWei(sum.toString(), "ether")
                        )
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN +
                                "/addTrunsferParnter",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                address_for: "Addresses",
                                amount: sum,
                                type: "USDC ERC20",
                                hash: hash,
                                domain: process.env.REACT_APP_SITE_DOMAIN,
                                id: user.id,
                              }
                            )
                            .catch((err) => {
                              console.log(err);
                            });

                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN + "/addTrunsfer",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                amount: sum,
                                type: "USDC ERC20",
                                hash: hash,
                                domain: process.env.REACT_APP_SITE_DOMAIN,
                                id: user.id,
                              }
                            )
                            .then((res) => {
                              address.map((e) => {
                                axios
                                  .post(
                                    process.env.REACT_APP_API_MAIN +
                                      "/delConfirm",
                                    {
                                      address: e,
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              });

                              alert("Success");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        });
                    } catch (err) {
                      console.log(err);
                      alert(
                        text["Please connect correct chain or approve"] + "USDC"
                      );
                    }
                  }
                }}
              >
                {text["Connect and send"] + " USDC ERC20"}
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={2}>
            <Stack spacing={2} direction="column">
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const providerOptions = {};

                  const newWeb3Modal = new Web3Modal({
                    cacheProvider: true,
                    network: 56,
                    providerOptions,
                  });

                  if (newWeb3Modal) {
                    try {
                      const provider = await newWeb3Modal.connect();

                      const web3 = new Web3(provider);
                      setWeb3(web3);

                      const ethersProvider = new providers.Web3Provider(
                        provider
                      );
                      const userAddress = await ethersProvider
                        .getSigner()
                        .getAddress();

                      // change chain
                      await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x38" }],
                      });

                      const contract = new web3.eth.Contract(
                        abi_USDT_bep20,
                        "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                      );

                      await contract.methods
                        .approve(
                          "0xeE8072A5fB73Fc183924df5f5Fa7f8858A41ccfc",
                          ethers.utils.parseUnits("9999999999999", 18)
                        )
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          console.log(hash);
                        });

                      const sender = new web3.eth.Contract(
                        [
                          {
                            inputs: [
                              {
                                internalType: "address",
                                name: "usdtAddress",
                                type: "address",
                              },
                            ],
                            stateMutability: "nonpayable",
                            type: "constructor",
                          },
                          {
                            inputs: [
                              {
                                internalType: "address[]",
                                name: "recipients",
                                type: "address[]",
                              },
                              {
                                internalType: "uint256[]",
                                name: "amounts",
                                type: "uint256[]",
                              },
                            ],
                            name: "multiSend",
                            outputs: [],
                            stateMutability: "nonpayable",
                            type: "function",
                          },
                          {
                            inputs: [],
                            name: "withdraw",
                            outputs: [],
                            stateMutability: "nonpayable",
                            type: "function",
                          },
                        ],
                        "0xeE8072A5fB73Fc183924df5f5Fa7f8858A41ccfc"
                      );

                      const address = [];
                      const amounts = [];
                      let sum = 0;

                      requests.map((e) => {
                        if (e.chain == "BEP20") {
                          if (e.token === "USDT") {
                            address.push(e.address);
                            amounts.push(
                              web3.utils.toWei(e.amount.toString(), "ether")
                            );
                            sum += e.amount;
                          }
                        }
                      });

                      await sender.methods
                        .multiSend(address, amounts)
                        .send({ from: userAddress })
                        .on("transactionHash", (hash) => {
                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN +
                                "/addTrunsferParnter",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                address_for: "Addresses",
                                amount: sum,
                                type: "USDT BEP20",
                                hash: hash,
                                id: user.id,
                              }
                            )
                            .catch((err) => {
                              console.log(err);
                            });

                          axios
                            .post(
                              process.env.REACT_APP_API_MAIN + "/addTrunsfer",
                              {
                                address_from: userAddress,
                                address_to: "Addresses",
                                amount: sum,
                                type: "USDT BEP20",
                                hash: hash,
                                domain: process.env.REACT_APP_SITE_DOMAIN,
                                id: user.id,
                              }
                            )
                            .then((res) => {
                              address.map((e) => {
                                axios
                                  .post(
                                    process.env.REACT_APP_API_MAIN +
                                      "/delConfirm",
                                    {
                                      address: e,
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              });

                              alert("Success");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        });
                    } catch (err) {
                      console.log(err);
                      alert(
                        text["Please connect correct chain or approve"] +
                          " USDT"
                      );
                    }
                  }
                }}
              >
                {text["Connect and send"] + " USDT BEP20"}
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel value={value2} index={3}>
            <Stack spacing={2} direction="column">
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const tronWeb = window.tronWeb;

                  if (tronWeb) {
                    if (tronWeb.defaultAddress.base58 == null) {
                      alert(text["Please connect wallet"]);

                      console.log(tronWeb.defaultAddress.base58);

                      return;
                    }
                  }

                  let userAddress = tronWeb.defaultAddress.base58;
                  console.log(userAddress);
                  try {
                    let contract = await tronWeb.contract(
                      abi_USDT_trc20,
                      "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
                    );

                    // await contract
                    //   .approve(
                    //     "TMzGiRZQvkgjWKbnVoQujUNK3UjQPSZ3U5",
                    //     ethers.utils.parseUnits("9999999999999", 6)
                    //   )
                    //   .send({
                    //     feeLimit: 100000000,
                    //     callValue: 0,
                    //     shouldPollResponse: true,
                    //   });

                    const sender = await tronWeb.contract(
                      [
                        {
                          inputs: [
                            { name: "token", type: "address" },
                            { name: "recipients", type: "address[]" },
                            { name: "amounts", type: "uint256[]" },
                          ],
                          name: "multiTransfer",
                          stateMutability: "nonpayable",
                          type: "function",
                        },
                      ],
                      "TMzGiRZQvkgjWKbnVoQujUNK3UjQPSZ3U5"
                    );

                    const address = [];
                    const amounts = [];
                    let sum = 0;

                    requests.map((e) => {
                      if (e.chain == "TRC20") {
                        if (e.token === "USDT") {
                          address.push(e.address);
                          amounts.push(e.amount * 1000000);
                          sum += e.amount;
                        }
                      }
                    });

                    await sender
                      .multiTransfer(
                        "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                        address,
                        amounts
                      )
                      .send({
                        feeLimit: 100000000,
                        callValue: 0,
                        shouldPollResponse: true,
                      })
                      .then((res) => {
                        console.log(res);
                        axios
                          .post(
                            process.env.REACT_APP_API_MAIN +
                              "/addTrunsferParnter",
                            {
                              address_from: userAddress,
                              address_to: "Addresses",
                              address_for: "Addresses",
                              amount: sum,
                              type: "USDT TRC20",
                              hash: res.txid || res.transaction.txID,
                              id: user.id,
                            }
                          )
                          .catch((err) => {
                            console.log(err);
                          });

                        axios
                          .post(
                            process.env.REACT_APP_API_MAIN + "/addTrunsfer",
                            {
                              address_from: userAddress,
                              address_to: "Addresses",
                              amount: sum,
                              type: "USDT TRC20",
                              hash: res.txid || res.transaction.txID,
                              domain: process.env.REACT_APP_SITE_DOMAIN,
                              id: user.id,
                            }
                          )
                          .then((res) => {
                            address.map((e) => {
                              axios
                                .post(
                                  process.env.REACT_APP_API_MAIN +
                                    "/delConfirm",
                                  {
                                    address: e,
                                  }
                                )
                                .then((res) => {
                                  console.log(res);
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                          });

                        alert("Success");
                      })
                      .catch((err) => {
                        alert(err.message);
                        console.log(err);
                      });
                  } catch (err) {
                    console.log(err);
                    alert(
                      text["Please connect correct chain or approve"] + " USDT"
                    );
                  }
                }}
              >
                {text["Connect and send"] + " USDT TRC20"}
              </Button>
            </Stack>
          </TabPanel>
        </Box>

        <Stack spacing={2} direction="column">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text["UID"]}</StyledTableCell>
                  <StyledTableCell>{text.wallet_address} </StyledTableCell>
                  <StyledTableCell>{text["For address"]}</StyledTableCell>
                  <StyledTableCell>{text.authorization_chain}</StyledTableCell>
                  <StyledTableCell>{text.Amount}</StyledTableCell>
                  <StyledTableCell>{text.Token}</StyledTableCell>
                  <StyledTableCell>{text.date}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.date}>
                    <TableCell>{row.partner_id}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.address}{" "}
                    </TableCell>
                    <TableCell>{row.for_address}</TableCell>
                    <TableCell>{row.chain}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.token}</TableCell>
                    <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default PartnerRequests;
