import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, TableHead } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import axios from "axios";
const cookies = new Cookies();

const options = localStorage.getItem("language")
  ? [
      lang[localStorage.getItem("language")]["pending"],
      lang[localStorage.getItem("language")]["Approved"],
      lang[localStorage.getItem("language")]["Rejected"],
    ]
  : ["Pending", "Approved", "Rejected"];

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, id2, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address, id2);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{text["Select the status of the request"]}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {text.Cancel}
        </Button>
        <Button onClick={handleOk}>{text.Ok}</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const WithdrawalRequests = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open2, setOpen2] = useState(false);
  const [value, setValue] = useState();
  const [change, setChange] = useState(0);

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  const handleClickListItem = () => {
    setOpen2(true);
  };

  const handleClose = (newValue, address, id) => {
    setOpen2(false);
    if (newValue) {
      setValue(newValue);

      const newRequests = [...requests];
      newRequests.map((item) => {
        if (item.id === id) {
          item.status = newValue;
        }
      });

      setRequests([]);
      setTimeout(() => {
        setRequests(newRequests);
      }, 100);

      axios.post(
        process.env.REACT_APP_API_URL + "/setNewStatusForWithdrawalRequest",
        {
          address: address,
          status:
            newValue === text["pending"]
              ? 0
              : newValue === text["Approved"]
              ? 1
              : 2,
          id: id,
        }
      );

      setChange(change + 1);

      if (newValue === text["Approved"]) {
        axios.post(process.env.REACT_APP_API_URL + "/addToMonitoring", {
          address: address,
        });
      }

      if (newValue === text["Rejected"]) {
        axios.post(process.env.REACT_APP_API_URL + "/returnWithdrawal", {
          id: id,
        });
      }
    }
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getWithdrawalRequests", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.requests);
        });
    } catch (err) {
      console.log(err);
    }
  }, [change, curr_user_id]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const returnStatus = (status) => {
    switch (status) {
      case 1:
        return text["Approved"];
      case 2:
        return text["Rejected"];
      default:
        return text["pending"];
    }
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.withdrawal_requests} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            {role === "admin" ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={all_users.map(
                  (option) => `${option.id} - ${option.login}`
                )}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  newValue
                    ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                    : setCurr_user_id(0);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={text.select_user} />
                )}
              />
            ) : null}

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={requests
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((option) => `${option.id} - ${option.address}`)}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRequests([
                    requests.filter(
                      (r) => r.id === parseInt(newValue.split(" - ")[0])
                    )[0],
                  ]);
                } else {
                  setCurr_user_id(user.id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.Address}</StyledTableCell>
                  <StyledTableCell>{text["User id"]}</StyledTableCell>
                  <StyledTableCell>{text["Network"]}</StyledTableCell>
                  <StyledTableCell>{text["Amount"]}</StyledTableCell>
                  <StyledTableCell>{text["Status"]}</StyledTableCell>
                  <StyledTableCell>{text["Date"]}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={`${new Date(row.date).toLocaleString()}`}>
                    <TableCell component="th" scope="row">
                      {row.address}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.network}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>
                      {(value ? value === text["pending"] : returnStatus(row.status) === text["pending"]) ? (
                        <>
                          <ListItem
                            button
                            divider
                            aria-haspopup="true"
                            aria-controls="status-menu"
                            onClick={handleClickListItem}
                          >
                            <ListItemText
                              primary={value ? value : returnStatus(row.status)}
                            />
                          </ListItem>
                          <ConfirmationDialogRaw
                            id="status-menu"
                            keepMounted
                            open={open2}
                            onClose={handleClose}
                            value={value ? value : returnStatus(row.status)}
                            address={row.address}
                            id2={row.uid}
                          />
                        </>
                      ) : (
                        returnStatus(row.status)
                      )}
                    </TableCell>
                    <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default WithdrawalRequests;
