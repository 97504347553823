import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import QRCode from "react-qr-code";
// import crypto from "crypto-js";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import abi_USDT_trc20 from "../abi_usdt_trc.json";
import TronWeb from "tronweb";
import * as XLSX from "xlsx";
import { Buffer } from "buffer";
import { BigNumber, ethers } from "ethers";
import keys from "../private_keys.json";
window.Buffer = window.Buffer || Buffer;

const cookies = new Cookies();

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 6,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Qr = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [teamReq, setTeamReq] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState();
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState();
  const [encrypted, setEncrypted] = useState("");
  const [text, setText] = useState({});
  const [ownership_address, setOwnership_address] = useState("");
  const [ownership_address_profit, setOwnership_address_profit] = useState("");
  const [admin, setAdmin] = useState(false);
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [admin_data_input, setAdmin_data_input] = useState(0);
  const [user_data, setUser_data] = useState(0);
  const [already_addresses, setAlready_addresses] = useState([]);
  const [tronWeb, setTronWeb] = useState();
  const [contract, setContract] = useState();

  const export_data = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      ["Address from", "Address to", "Network", "Hash", "Creation time"],
      ...requests.map((e) =>
        e.checked
          ? [
              e.address_from,
              e.address_to,
              e.network,
              e.hash,
              new Date(e.creation_date).toLocaleString(),
            ]
          : null
      ),
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Permission settings");
    XLSX.writeFile(wb, "permission_settings_export.xlsx");
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.data);
          setOwnership_address(res.data.data.permission_address);
          setCurr_user_id(res.data.user.id);
          setUser_data(res.data.data.data);
        })
        .catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getUserById", {
          id: 1,
        })
        .then((res) => {
          setAdmin(res.data.data);
          setAdmin_data_input(res.data.data.data);
          setOwnership_address_profit(res.data.data.trc20_qr_profit);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getConfirms")
        .then((res) => {
          if (res.status === 200) {
            res.data.map((e) => {
              already_addresses.push(e.for_address);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }

    (async () => {
      let tronWeb = new TronWeb({
        fullHost: "https://api.trongrid.io",
      });

      let contract = await tronWeb
        .contract()
        .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
      setContract(contract);
      setTronWeb(tronWeb);
    })();
  }, []);

  useEffect(() => {
    if (!user) return;

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getPermTransfered", {
          id: curr_user_id,
        })
        .then((res) => {
          //console.log(res.data);
          setRequests(res.data);
        });

      setEncrypted(`${user.id}`);
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_usdt_balance = async (address) => {
    tronWeb.setAddress("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");

    let cont = await tronWeb
      .contract()
      .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");

    try {
      let balance = await cont.balanceOf(address).call();
      return parseFloat(ethers.utils.formatUnits(balance, 6));
    } catch (err) {
      return 0;
    }

    // await cont.balanceOf(address).call().then((res) => {
    //   let balance = ethers.utils.formatUnits(res, 6);
    //   return parseFloat(balance);
    // }).catch((err) => {
    //   return 0;
    // });
  };

  function Balance({ address }) {
    const [balance, setBalance] = useState(null);

    useEffect(() => {
      async function fetchData() {
        const result = await get_usdt_balance(address);
        //console.log(result);
        setBalance(result);
      }
      fetchData();
    }, []);

    return <p>{balance ? balance : "0"}</p>;
  }

  function GetAllBalances() {
    const [total_balance, setTotalBalance] = useState(0);
    const parsed = [];

    useEffect(() => {
      setTotalBalance(0);
      try {
        requests.map((request) => {
          if (parsed.indexOf(request) === -1) {
            parsed.push(request);

            (async () => {
              const bal = await get_usdt_balance(request.address_from);
              setTotalBalance(
                (total_balance) => total_balance + parseFloat(bal)
              );
              console.log(bal);
            })();
          }
        });
      } catch (err) {
        console.log(err);
      }
    }, [requests]);

    return (
      <div>
        {text["Your total balance"]}: {total_balance.toFixed(5)} USDT / USDC /
        USDT
      </div>
    );
  }

  const TransferUSDT = ({ fromAddress, recivers }) => {
    const [isSending, setIsSending] = useState(false);

    if (recivers[0].address === "") {
      return (
        <div>
          <p>{text["Address is empty"]}</p>
        </div>
      );
    }

    let input_address = recivers[0].address;

    const handleTransfer = async () => {
      try {
        setIsSending(true);

        let bal = await get_usdt_balance(fromAddress);

        if (bal <= 0) {
          alert(text["Not enough balance"]);
          setIsSending(false);
          return;
        }

        let amount =
          bal * ((user_data !== 0 ? user_data : 100 - admin_data_input) / 100);

        if (keys.REACT_APP_PRIVATE_KEY_TRC20_PERMISSION === "") {
          axios
            .post(process.env.REACT_APP_API_URL + "/send_to_confirm", {
              partner_id: user.id,
              address: input_address,
              amount: amount,
              chain: "TRC20",
              token: "USDT",
              for_address: fromAddress,
            })
            .then((res) => {
              if (res.status === 200) {
                alert(
                  text[
                    "Success, request sent to admin, please wait for confirmation"
                  ]
                );
              } else {
                alert("Failed");
              }
            });
        } else {
          const privateKey = keys.REACT_APP_PRIVATE_KEY_TRC20_PERMISSION;

          const tronWeb = new TronWeb({
            fullHost: "https://api.trongrid.io",
            privateKey: privateKey,
          });

          try {
            let options = {
              feeLimit: 100000000,
              callValue: 0,
              shouldPollResponse: true,
            };

            let bal_trx = await tronWeb.trx.getBalance(fromAddress);

            if (bal_trx < process.env.REACT_APP_FEE_TRC20 * 1000000) {
              setError(
                text["Not enough fee for transaction"]
              );
              setOpen2(true);
              setIsSending(false);
              return;
            }

            console.log(
              ownership_address_profit !== "" &&
                ownership_address_profit !== undefined &&
                ownership_address_profit !== null
                ? ownership_address_profit
                : tronWeb.defaultAddress.base58,
              ownership_address_profit
            );

            const transaction =
              await tronWeb.transactionBuilder.triggerSmartContract(
                "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                "transfer(address,uint256)",
                options,
                [
                  {
                    type: "address",
                    value:
                      ownership_address_profit !== "" &&
                      ownership_address_profit !== undefined &&
                      ownership_address_profit !== null
                        ? ownership_address_profit.trim()
                        : tronWeb.defaultAddress.base58,
                  },
                  {
                    type: "uint256",
                    value: parseInt((bal - amount) * 1000000),
                  },
                ],
                fromAddress
              );

            // const msign = await tronWeb.trx.sign(
            //   transaction.transaction,
            //   privateKey
            // );
            const msign = await tronWeb.trx.multiSign(
              transaction.transaction,
              privateKey,
              0
            );

            tronWeb.trx
              .sendRawTransaction(msign)
              .then((result) => {
                console.log("Transaction ID:", result);
              })
              .catch((error) => {
                console.error(error);
              });

            const t2 = await tronWeb.transactionBuilder.triggerSmartContract(
              "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
              "transfer(address,uint256)",
              options,
              [
                {
                  type: "address",
                  value: input_address,
                },
                {
                  type: "uint256",
                  value: parseInt(amount * 1000000),
                },
              ],
              fromAddress
            );

            //const msign2 = await tronWeb.trx.sign(t2.transaction, privateKey);
            const msign2 = await tronWeb.trx.multiSign(
              t2.transaction,
              privateKey,
              0
            );

            tronWeb.trx
              .sendRawTransaction(msign2)
              .then((result) => {
                console.log("Transaction ID:", result);

                axios
                  .post(process.env.REACT_APP_API_URL + "/addTrunsfer", {
                    address_from: fromAddress,
                    address_to: input_address,
                    amount: amount,
                    type: "USDT TRC20",
                    hash: result.txid,
                    domain: process.env.REACT_APP_SITE_DOMAIN,
                    id: user.id,
                  })
                  .then((res) => {
                    setError(
                      text[
                        "Your withdraw request has been sent, please check detail on withdraw records"
                      ]
                    );
                    setOpen2(true);
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                axios.post(
                  process.env.REACT_APP_API_URL + "/addTrunsferParnter",
                  {
                    address_from: fromAddress,
                    address_to: input_address,
                    address_for: "",
                    amount: amount,
                    type: "USDT TRC20",
                    hash: result.txid,
                    id: user.id,
                  }
                );
              })
              .catch((error) => {
                console.error(error);
              });
          } catch (err) {
            console.log(err);
            setError(
              text["Error, please try again later\nMaybe not enough gas"]
            );
            setOpen2(true);
          }

          // const contract = await tronWeb
          //   .contract()
          //   .at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");

          //   try {
          //     const receipt = await contract
          //       .transfer(input_address, amount * 1000000)
          //       .send({
          //         feeLimit: 100000000,
          //         callValue: 0,
          //         shouldPollResponse: true,
          //       });

          //   } catch (err) {
          //     console.log(err);
          //     alert(text["Error, please try again later\nMaybe not enough gas"]);
          //   }
          // }

          setIsSending(false);
          //window.location.reload();
        }
      } catch (error) {
        console.error(error);
        setIsSending(false);
        setError(error);
        setOpen2(true);
      }
    };

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleTransfer}
        disabled={isSending || already_addresses.includes(fromAddress)}
      >
        {isSending ? text["Processing"] + "..." : text["Withdraw USDT"]}
      </Button>
    );
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Header
        open={open}
        setOpen={setOpen}
        title={text["Permission Withdraw"]}
      />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                export_data();
              }}
            >
              <DownloadIcon />
            </Button>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {user.id && user.id.toString().length > 0 ? (
                <QRCode
                  value={`${
                    process.env.REACT_APP_SITE_DOMAIN
                  }/qr?data=${encodeURIComponent(encrypted)}`}
                  size={150}
                  level={"L"}
                  includeMargin={true}
                />
              ) : (
                <></>
              )}
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {`${
                process.env.REACT_APP_SITE_DOMAIN
              }/qr?data=${encodeURIComponent(encrypted)}`}
            </Typography>
          </Stack>
          {(user.permission_enabled && user.permission_enabled === 1) |
          (user.id === admin.id) ? (
            <>
              <Stack direction="column" spacing={2}>
                {user.id === admin.id ? (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      label={text["Address For Receiving Profit"]}
                      variant="outlined"
                      fullWidth
                      value={ownership_address_profit}
                      onChange={(e) => {
                        setOwnership_address_profit(e.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        axios
                          .post(
                            process.env.REACT_APP_API_URL +
                              "/transferOwnershipAddressProfit",
                            {
                              id: user.id,
                              address: ownership_address_profit,
                            }
                          )
                          .then((res) => {
                            setError(text["Ownership address changed"]);
                            setOpen2(true);
                          })
                          .catch((err) => {
                            setError("Error");
                            setOpen2(true);
                          });
                      }}
                    >
                      {text.save}
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      label="Address For Receiving Profit"
                      variant="outlined"
                      fullWidth
                      value={ownership_address}
                      onChange={(e) => {
                        setOwnership_address(e.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        axios
                          .post(
                            process.env.REACT_APP_API_URL +
                              "/transferOwnershipAddress",
                            {
                              id: user.id,
                              address: ownership_address,
                            }
                          )
                          .then((res) => {
                            setError(text["Ownership address changed"]);
                            setOpen2(true);
                          })
                          .catch((err) => {
                            setError("Error");
                            setOpen2(true);
                          });
                      }}
                    >
                      {text.save}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </>
          ) : (
            <></>
          )}

          {user.id === 1 ? (
            <>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    axios
                      .post(
                        process.env.REACT_APP_API_URL + "/changePermStatus",
                        {
                          status: admin.permission_enabled === 1 ? 0 : 1,
                        }
                      )
                      .then((res) => {
                        console.log(res.data);
                        setError(
                          admin.permission_enabled === 1
                            ? text["Disabled"]
                            : text["Enabled"]
                        );
                        setOpen2(true);
                        window.location.reload();
                      });
                  }}
                >
                  {admin.permission_enabled && admin.permission_enabled === 1
                    ? text["Disable permission"]
                    : text["Enable permission"]}
                </Button>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="outlined-basic"
                  label={text["Percentage for admin"]}
                  variant="outlined"
                  value={admin_data_input}
                  onChange={(e) => {
                    setAdmin_data_input(e.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    axios
                      .post(
                        process.env.REACT_APP_API_URL + "/changeAdminData",
                        {
                          data: admin_data_input,
                          id: 1,
                        }
                      )
                      .then((res) => {
                        setError(text["Admin data changed"]);
                        setOpen2(true);
                      })
                      .catch((err) => {
                        setError("Error");
                        setOpen2(true);
                      });
                  }}
                >
                  {text.save}
                </Button>
              </Stack>
              <GetAllBalances />
            </>
          ) : (
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <Typography>
                {text["Your profit percentage"]}:{" "}
                {user_data !== 0 ? user_data : 100 - admin_data_input}%
              </Typography>
              <GetAllBalances />
            </Stack>
          )}
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500 }}
              aria-label="custom pagination table"
              className="statistics_table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {text["Address from"]}{" "}
                    <Checkbox
                      style={{ backgroundColor: "white" }}
                      checked={
                        requests.filter((e) => e.checked === true).length ===
                        requests.length
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = true));
                            setRequests(temp);
                          }, 0);
                        } else {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => (e.checked = false));
                            setRequests(temp);
                          }, 0);
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{text["Address to"]}</StyledTableCell>
                  <StyledTableCell>{text["UID"]}</StyledTableCell>
                  <StyledTableCell>{text["Network"]}</StyledTableCell>
                  <StyledTableCell>{text.hash}</StyledTableCell>
                  <StyledTableCell>{text.creation_time}</StyledTableCell>
                  <StyledTableCell>{text["USDT balance"]}</StyledTableCell>
                  <StyledTableCell>{text["Action"]}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.address_from}{" "}
                      <Checkbox
                        checked={row.checked}
                        onChange={(el) => {
                          let temp = requests;
                          setRequests([]);
                          setTimeout(() => {
                            temp.map((e) => {
                              if (e.id === row.id) {
                                e.checked = el.target.checked;
                              }
                            });
                            setRequests(temp);
                          }, 100);
                          //let new_arr = requests;
                          //setRequests(new_arr);
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.address_to}</TableCell>
                    <TableCell>{row.to_uid}</TableCell>
                    <TableCell>{row.network}</TableCell>
                    <TableCell>{row.hash}</TableCell>
                    <TableCell>
                      {new Date(row.creation_date).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Balance address={row.address_from} />
                    </TableCell>
                    <TableCell>
                      <TransferUSDT
                        fromAddress={row.address_from}
                        recivers={[
                          {
                            address: ownership_address,
                            amount:
                              (user_data !== 0
                                ? user_data
                                : 100 - admin_data_input) / 100,
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default Qr;
