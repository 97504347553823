import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Switch, TextField, Typography } from "@mui/material";
import { Grid, Container } from "@mui/material";
import axios from "axios";

const cookies = new Cookies();

const Settings = () => {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [site_title, setSite_title] = useState("");
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);

  const [image, setImage] = useState("");

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();

  function ImageUploader() {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
      const formData = new FormData();
      formData.append("image", selectedFile, selectedFile.name);

      if (image !== "") {
        console.log(image);
        axios
          .delete(
            process.env.REACT_APP_API_URL +
              "/delete-file/" +
              image.split("/")[image.split("/").length - 1]
          )
          .catch((error) => {
            console.log(error);
          });
      }

      axios
        .post(process.env.REACT_APP_API_URL + "/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const path =
            process.env.REACT_APP_API_URL + "/uploads/" + response.data.path;
          setImage(path);

          axios
            .post(process.env.REACT_APP_API_URL + "/updateFavicon", {
              favicon: path,
              id: curr_user_id,
            })
            .then((response) => {
              setError(text["Success"]);
              setOpen2(true);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          setError("Error!");
          setOpen2(true);
        });
    };

    const delFavicon = () => {
      if (image !== "") {
        axios
          .delete(
            process.env.REACT_APP_API_URL +
              "/delete-file/" +
              image.split("/")[image.split("/").length - 1]
          )
          .catch((error) => {
            console.log(error);
          });
      }

      setImage("");

      axios
        .post(process.env.REACT_APP_API_URL + "/updateFavicon", {
          favicon: "",
          id: curr_user_id,
        })
        .then((response) => {
          setError(text["Success"]);
          setOpen2(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <Stack spacing={4} direction="column" justifyContent={"center"}>
        <Stack spacing={2} direction="row" alignItems={"center"} maxWidth={800}>
          <Typography variant="h6">
            {text["Upload the favicon for your site"]}
          </Typography>

          {image !== "" ? (
            <img src={image} alt="" style={{ width: "50px", height: "50px" }} />
          ) : (
            <></>
          )}

          <input
            accept=".ico"
            style={{ display: "none" }}
            id="image-file"
            type="file"
            onChange={handleFileSelect}
          />
        </Stack>

        <Stack spacing={2} direction="row" alignItems={"center"}>
          <label htmlFor="image-file">
            <Button variant="contained" color="primary" component="span">
              {text["Select an image to upload"]}
            </Button>
          </label>

          <Button variant="contained" color="primary" onClick={delFavicon}>
            {text["Delete"]}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!selectedFile}
            onClick={handleUpload}
          >
            {text["Upload"]}
          </Button>
        </Stack>
      </Stack>
    );
  }

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        })
        .catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });
    } catch (err) {
      console.log(err);
    }

    setData(JSON.parse(localStorage.getItem("data_settings")));
  }, []);

  useEffect(() => {
    console.log(curr_user_id);
    if (curr_user_id) {
      axios
        .post(process.env.REACT_APP_API_URL + "/getFavicon", {
          id: curr_user_id,
        })
        .then((res) => {
          setImage(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .post(process.env.REACT_APP_API_URL + "/getTitle", {
          id: curr_user_id,
        })
        .then((res) => {
          setSite_title(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [curr_user_id]);

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem("data_settings");
      if (item) {
        setData(JSON.parse(item));
      }
    }

    checkUserData();

    window.addEventListener("storage", checkUserData);

    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const updateData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updateData", {
        id: curr_user_id,
        newData: data,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("data_settings", JSON.stringify(data));
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(process.env.REACT_APP_API_URL + "/updateTitle", {
        id: curr_user_id,
        title: site_title,
      })
      .then((res) => {
        if (res.status === 200) {
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.site_settings} />
      <Content open={open}>
        <Snackbar
          open={open2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          {data
            ? data.map((item, key) => {
                return (
                  <Stack
                    spacing={2}
                    key={key}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography variant="h6" width={400}>
                      {text[item.field_name]}
                    </Typography>
                    {item.data_key === "blockchain_mode" ||
                    item.data_key === "earning_mode" ? (
                      <Switch
                        defaultChecked={
                          typeof data[key].value === "string"
                            ? Boolean(parseInt(data[key].value))
                            : data[key].value
                        }
                        value={
                          typeof data[key].value === "string"
                            ? Boolean(parseInt(data[key].value))
                            : data[key].value
                        }
                        onChange={(e) => {
                          let newData = [...data];
                          newData[key].value = e.target.checked;
                          setData(newData);
                        }}
                      />
                    ) : (
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={data[key].value}
                        onChange={(e) => {
                          let newData = [...data];
                          if (item.data_key === "coin_price") {
                            if (isNaN(e.target.value)) {
                              newData[key].value = 1;
                            } else {
                              newData[key].value = e.target.value;
                            }
                          } else {
                            newData[key].value = e.target.value;
                          }
                          setData(newData);
                        }}
                      />
                    )}
                  </Stack>
                );
              })
            : null}

          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="h6" width={400}>
              {text["Set title for your site"]}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={site_title}
              onChange={(e) => {
                setSite_title(e.target.value);
              }}
            />
          </Stack>

          <ImageUploader />
          <Button variant="contained" onClick={updateData}>
            {text.save}
          </Button>
        </Stack>
      </Content>
    </div>
  );
};

export default Settings;
