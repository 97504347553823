import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import lang from "../lang/language.json";

const cookies = new Cookies();

const SocialChange = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [telegram, setTelegram] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [instagram, setInstagram] = useState();
  const [linkedin, setLinkedin] = useState();
  const [youtube, setYoutube] = useState();
  const [watsapp, setWatsapp] = useState();
  const [line, setLine] = useState();
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [user, setUser] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);

    axios
      .post(process.env.REACT_APP_API_URL + "/getUser", {
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        cookies.remove("refreshToken");
        navigate("/login");
      });
  }, []);

  useEffect(() => {
    if (user) {
      axios
        .post(process.env.REACT_APP_API_URL + "/getAllSocial", {
          id: user.id,
        })
        .then((res) => {
          setTelegram(res.data.telegram);
          setFacebook(res.data.facebook);
          setTwitter(res.data.twitter);
          setInstagram(res.data.instagram);
          setLinkedin(res.data.linkedin);
          setYoutube(res.data.youtube);
          setWatsapp(res.data.watsapp);
          setLine(res.data.line);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const updateData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updateSocial", {
        id: user.id,
        social: {
          telegram: telegram,
          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
          linkedin: linkedin,
          youtube: youtube,
          watsapp: watsapp,
          line: line,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(
            "social",
            JSON.stringify({
              telegram: telegram,
              facebook: facebook,
              twitter: twitter,
              instagram: instagram,
              linkedin: linkedin,
              youtube: youtube,
              watsapp: watsapp,
              line: line,
            })
          );
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((err) => {
        setError(text.error_updating_data);
        handleClick();
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.social_change} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          <Typography variant="h6">
            {text.social_change}:
          </Typography>

          <TextField
            fullWidth
            label="Telegram"
            id="outlined-basic"
            variant="outlined"
            value={telegram}
            onChange={(e) => {
              setTelegram(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Facebook"
            id="outlined-basic"
            variant="outlined"
            value={facebook}
            onChange={(e) => {
              setFacebook(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Twitter"
            id="outlined-basic"
            variant="outlined"
            value={twitter}
            onChange={(e) => {
              setTwitter(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Instagram"
            id="outlined-basic"
            variant="outlined"
            value={instagram}
            onChange={(e) => {
              setInstagram(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Linkedin"
            id="outlined-basic"
            variant="outlined"
            value={linkedin}
            onChange={(e) => {
              setLinkedin(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Youtube"
            id="outlined-basic"
            variant="outlined"
            value={youtube}
            onChange={(e) => {
              setYoutube(e.target.value);
            }}
            focused
          />
          <TextField
            fullWidth
            label="Whatsapp"
            id="outlined-basic"
            variant="outlined"
            value={watsapp}
            onChange={(e) => {
              setWatsapp(e.target.value);
            }}
            focused
          />

          <TextField
            fullWidth
            label="Line"
            id="outlined-basic"
            variant="outlined"
            value={line}
            onChange={(e) => {
              setLine(e.target.value);
            }}
            focused
          />

          <Button variant="contained" onClick={updateData}>
            {text.save}
          </Button>
        </Stack>
      </Content>
    </div>
  );
};

export default SocialChange;
