import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import lang from "../lang/language.json";

import axios from "axios";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState("");

  const [text, setText] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        }).catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getIntegralStatistics", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
          console.log(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id]);

  const type_name = (type) => {
    if (type === 0) {
      return text.register_bonus;
    } else if (type === 1) {
      return text.exchange;
    } else if (type === 2) {
      return text.permission;
    } else if (type === 3) {
      return text.withdrawal;
    } else if (type === 4) {
      return text.daily_earnings;
    } else if (type === 5) {
      return text.staking_activities;
    } else if (type === 6) {
      return text.divide_activities;
    } else if (type === 9) {
      return "Withdrawal rejected";
    } else if (type === 12) {
      return "Referral Commissions";
    } else if (type === 17) {
      return "Staking lock";
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.integral_statistics} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            {role === "admin" ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={all_users.map(
                  (option) => `${option.id} - ${option.login}`
                )}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  newValue
                    ? setCurr_user_id(parseInt(newValue.split(" - ")[0]))
                    : setCurr_user_id(0);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={text.select_user} />
                )}
              />
            ) : null}

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={requests
                .filter((value, index, self) => {
                  return index === self.findIndex((t) => t.id === value.id);
                })
                .map((option) => `${option.id} - ${option.wallet_address}`)}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue != null) {
                  setRequests(
                    requests.filter(
                      (r) => r.id === parseInt(newValue.split(" - ")[0])
                    )
                  );
                } else {
                  setCurr_user_id();
                  setTimeout(() => {
                    setCurr_user_id(user.id);
                  }, 100);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text.wallet_address}</StyledTableCell>
                  <StyledTableCell>{text.awards}</StyledTableCell>
                  <StyledTableCell>{text.quantity}</StyledTableCell>
                  <StyledTableCell>{text.object}</StyledTableCell>
                  <StyledTableCell>{text.creation_time}</StyledTableCell>
                  <StyledTableCell>{text.remark}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow
                    key={`${new Date(row.date).toLocaleString()}${row.amount}${
                      row.wallet_address
                    }`}
                  >
                    <TableCell>{row.wallet_address}</TableCell>
                    <TableCell>
                      {row.type === 4
                        ? type_name(row.type) + ": " + row.remark
                        : type_name(row.type)}
                    </TableCell>
                    <TableCell>
                      {row.coin === "USDT"
                        ? `USDT: ${row.amount}`
                        : `Token: ${row.amount}`}
                    </TableCell>
                    <TableCell>{row.wallet_address}</TableCell>
                    <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                    <TableCell>{row.remark}</TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
    </div>
  );
};

export default Settings;
