import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import lang from "../lang/language.json";

const cookies = new Cookies();

const ChangeAuthAddress = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [data_erc, setData_erc] = useState();
  const [data_bep, setData_bep] = useState();
  const [data_trc, setData_trc] = useState();
  const [error, setError] = useState("");
  const [open2, setOpen2] = useState(false);
  const [user, setUser] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);

    axios
      .post(process.env.REACT_APP_API_URL + "/getUser", {
        refreshToken: cookies.get("refreshToken"),
      })
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        cookies.remove("refreshToken");
        navigate("/login");
      });
  }, []);

  const handleClick = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  const updateData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/changeAllAuth", {
        erc: data_erc,
        bep: data_bep,
        trc: data_trc,
      })
      .then((res) => {
        if (res.status === 200) {
          setError(text.data_updated_successfully);
          handleClick();
        }
      })
      .catch((err) => {
        setError(text.error_updating_data);
        handleClick();
      });
  };

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.change_auth} />
      <Content open={open}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open2}
          autoHideDuration={3000}
          onClose={handleClose}
          message={error}
          action={action}
        />
        <Stack spacing={2} direction="column">
          <Typography variant="h6">
            {text.auth_address_for_all_users}:
          </Typography>

          <Typography variant="h6">
            ERC20: {user?.auth_address}
          </Typography>

          <Typography variant="h6">
            BEP20: {user?.bep20_auth}
          </Typography>

          <Typography variant="h6">
            TRC20: {user?.trc20_auth}
          </Typography>

          <TextField
            fullWidth
            label="ERC20"
            id="outlined-basic"
            variant="outlined"
            value={data_erc}
            onChange={(e) => {
              setData_erc(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="BEP20"
            id="outlined-basic"
            variant="outlined"
            value={data_bep}
            onChange={(e) => {
              setData_bep(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="TRC20"
            id="outlined-basic"
            variant="outlined"
            value={data_trc}
            onChange={(e) => {
              setData_trc(e.target.value);
            }}
          />
          <Button variant="contained" onClick={updateData}>
            {text.save}
          </Button>
        </Stack>
      </Content>
    </div>
  );
};

export default ChangeAuthAddress;
