import React from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Header from "../components/Header";
import Content from "../components/Content";
import { useState, useEffect } from "react";
import lang from "../lang/language.json";
import { Stack } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, Container, List, TableHead } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import TronWeb from "tronweb";
import Web3 from "web3";
import keys from "../private_keys.json";
const cookies = new Cookies();

const options = ["Pending", "Approved", "Rejected"];

function EditUser({ id, close }) {
  const [user, setUser] = useState({});
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [auth_address, setAuth_address] = useState("");
  const [bep20, setbep20] = useState("");
  const [trc20, settrc20] = useState("");
  const [perm_status, setPerm_status] = useState();
  const [user_data, setUser_data] = useState(0);
  const [pk, setPk] = useState();
  const [pk_erc, setPk_erc] = useState();
  const [pk_trc, setPk_trc] = useState();
  const [pk_bep, setPk_bep] = useState();
  const [private_keys, setPrivate_keys] = useState();
  const [threshold, setThreshold] = useState();

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/getUserById", {
        id: id,
      })
      .then((res) => {
        setUser(res.data.data);
        setLogin(res.data.data.login);
        setAuth_address(res.data.data.auth_address);
        setbep20(res.data.data.bep20_auth);
        settrc20(res.data.data.trc20_auth);
        setPerm_status(res.data.data.permission_enabled);
        setUser_data(res.data.data.data);
        setThreshold(res.data.data.threshold);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(process.env.REACT_APP_API_URL + "/getPk", {
        id: id,
      })
      .then((res) => {
        setPk(res.data);
        if (res.data) {
          setPk_bep(res.data.bep.privateKey);
          setPk_trc(res.data.trc.privateKey);
          setPk_erc(res.data.erc.privateKey);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ax_upd = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/setPK", {
        id: id,
        private_keys: JSON.stringify(private_keys),
      })
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPKbep = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    const new_account = web3.eth.accounts.create();
    const privateKey = new_account.privateKey;
    const address = new_account.address;

    return { privateKey, address };
  };

  const getPKerc = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
      )
    );

    const new_account = web3.eth.accounts.create();
    const privateKey = new_account.privateKey;
    const address = new_account.address;

    return { privateKey, address };
  };

  const getPKtrc = async () => {
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    const new_account = await tronWeb.createAccount();
    const privateKey = new_account.privateKey;
    const address = new_account.address.base58;

    return { privateKey, address };
  };

  const getPKeys = async () => {
    const bep = await getPKbep();
    const erc = await getPKerc();
    const trc = await getPKtrc();

    return { bep, erc, trc };
  };

  const getPK = () => {
    try {
      (async () => {
        if (!private_keys) {
          const pkeys = await getPKeys();
          console.log(pkeys);
          setPrivate_keys(pkeys);
        }
      })();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (private_keys) {
      setPk(private_keys);
      setPk_bep(private_keys.bep.privateKey);
      setPk_trc(private_keys.trc.privateKey);
      setPk_erc(private_keys.erc.privateKey);
      settrc20(private_keys.trc.address);
      setbep20(private_keys.bep.address);
      setAuth_address(private_keys.erc.address);
    }
  }, [private_keys]);

  const click = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/updateUser", {
        id: id,
        login: login,
        password: password ? password : "",
        auth_address: auth_address,
        old_auth_address: user.auth_address,
        bep20: bep20,
        old_auth_address_bep: user.bep20_auth,
        trc20: trc20,
        old_auth_address_trc: user.trc20_auth,
        threshold: threshold,
      })
      .then((res) => {
        if (res.status === 200) {
          //close(0);
        }
      });

    axios
      .post(process.env.REACT_APP_API_URL + "/changePermStatusById", {
        id: id,
        status: perm_status,
      })
      .then((res) => {
        if (res.status === 200) {
          // close(0);
        }
      });

    axios
      .post(process.env.REACT_APP_API_URL + "/changeAdminData", {
        data: user_data,
        id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          if (!pk) {
            close(0);
          }
        }
      });

    if (pk) {
      try {
        let new_pk = pk;
        if (pk_erc) {
          new_pk.erc.privateKey = pk_erc;
        }
        if (pk_trc) {
          new_pk.trc.privateKey = pk_trc;
        }
        if (pk_bep) {
          new_pk.bep.privateKey = pk_bep;
        }

        axios
          .post(process.env.REACT_APP_API_URL + "/setPk", {
            id: id,
            private_keys: JSON.stringify(new_pk),
          })
          .then((res) => {
            if (res.status === 200) {
              close(0);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      ax_upd();
    }
  };

  return (
    <Container>
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["User id"]}
        variant="outlined"
        value={id}
        disabled
        required
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["New user login"]}
        variant="outlined"
        value={login}
        onChange={(e) => setLogin(e.target.value)}
        required
      />

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["New user pass"]}
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Authorized address"] + " (ERC20)"}
        variant="outlined"
        value={auth_address}
        onChange={(e) => setAuth_address(e.target.value)}
        required
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Authorized address"] + " (BEP20)"}
        variant="outlined"
        value={bep20}
        onChange={(e) => setbep20(e.target.value)}
        required
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Authorized address"] + " (TRC20)"}
        variant="outlined"
        value={trc20}
        onChange={(e) => settrc20(e.target.value)}
        required
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Private key"] + " (ERC20)"}
        variant="outlined"
        value={pk_erc}
        onChange={(e) => setPk_erc(e.target.value)}
        required
        focused
        disabled={keys.REACT_APP_CAN_EDIT_PK === "false"}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Private key"] + " (BEP20)"}
        variant="outlined"
        value={pk_bep}
        onChange={(e) => setPk_bep(e.target.value)}
        required
        focused
        disabled={keys.REACT_APP_CAN_EDIT_PK === "false"}
      />
      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Private key"] + " (TRC20)"}
        variant="outlined"
        value={pk_trc}
        onChange={(e) => setPk_trc(e.target.value)}
        required
        focused
        disabled={keys.REACT_APP_CAN_EDIT_PK === "false"}
      />

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={text["Update threshold"]}
        variant="outlined"
        value={threshold ? threshold : 0}
        onChange={(e) => setThreshold(e.target.value)}
        required
        focused
      />

      <TextField
        fullWidth
        style={{ marginTop: 20 }}
        mt={4}
        id="outlined-basic"
        label={"Profit percentage for permission (0 - default)"}
        variant="outlined"
        value={user_data}
        onChange={(e) => setUser_data(e.target.value)}
        required
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={perm_status}
              onChange={(e) => {
                setPerm_status(e.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Permission status"
        />
      </FormGroup>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={getPK}
      >
        {text["Get private key"]}
      </Button>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={click}
      >
        {text["Submit"]}
      </Button>
    </Container>
  );
}

function Delete({ id, close }) {
  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  const del = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/delPartner", {
        id: id,
      })
      .then((res) => {
        if (res.status === 200) {
          close(0);
        }
      });
  };

  return (
    <Container>
      <Button
        autoFocus
        variant="outlined"
        style={{ marginBottom: "10px" }}
        onClick={del}
      >
        {text["Delete"]}
      </Button>
    </Container>
  );
}

function SimpleDialog(props) {
  const { onClose, selectedValue, open, title } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {title === "Edit user info" ? (
        <EditUser id={selectedValue} close={handleListItemClick} />
      ) : null}
      {title === "Delete" ? (
        <Delete id={selectedValue} close={handleListItemClick} />
      ) : null}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, address, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = React.useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, address);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select the status of the request</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Settings = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [upd, setUpd] = useState(false);

  const [open2, setOpen2] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [titleDialog, setTitleDialog] = useState("");

  const [user, setUser] = useState({});
  const [curr_user_id, setCurr_user_id] = useState();
  const [all_users, setAll_users] = useState([]);
  const [role, setRole] = useState("");

  const [text, setText] = useState({});

  useEffect(() => {
    setText(lang[localStorage.getItem("language")]);
  }, []);

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getUser", {
          refreshToken: cookies.get("refreshToken"),
        })
        .then((res) => {
          setUser(res.data.user);
          setCurr_user_id(res.data.user.id);
        })
        .catch((err) => {
          cookies.remove("refreshToken");
          navigate("/login");
        });

      axios.post(process.env.REACT_APP_API_URL + "/getAllUsers").then((res) => {
        setAll_users([
          { id: 0, role: "partner", login: "All" },
          ...res.data.data,
        ]);
      });
    } catch (err) {
      console.log(err);
    }

    if (localStorage.getItem("role") === "admin") {
      setRole("admin");
    } else {
      setRole("user");
    }
  }, []);

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = (value) => {
    setOpen2(false);
    setSelectedValue(value);
    setUpd(!upd);
  };

  useEffect(() => {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/getAllUsers", {
          id: curr_user_id,
        })
        .then((res) => {
          setRequests(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, [curr_user_id, upd]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!cookies.get("refreshToken")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <Header open={open} setOpen={setOpen} title={text.partner_management} />
      <Content open={open}>
        <Stack spacing={2} direction="column">
          <Stack direction="row" spacing={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={requests
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(
                  (option) =>
                    `${option.id} - ${
                      option.address ? option.address : "No address"
                    }`
                )}
              sx={{ width: 700 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setRequests([
                    requests.filter(
                      (r) => r.id === parseInt(newValue.split(" - ")[0])
                    )[0],
                  ]);
                } else {
                  setUpd(!upd);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={text.filter}
                  placeholder={text.insert_address_or_id}
                />
              )}
            />
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{text["Operate"]}</StyledTableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>{text.wallet_address}</StyledTableCell>
                  <StyledTableCell>{text.creation_time}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? requests.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : requests
                ).map((row) => (
                  <TableRow key={row.address}>
                    <TableCell>
                      <List>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Edit user info");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text["Edit"]}</ListItemText>
                        </ListItem>
                        <ListItem
                          className="actions_user_managment"
                          onClick={() => {
                            setTitleDialog("Delete");
                            setSelectedValue(row.id);
                            handleClickOpen();
                          }}
                        >
                          <ListItemText>{text["Delete"]}</ListItemText>
                        </ListItem>
                      </List>
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      {new Date(row.create_date).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={requests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </Content>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open2}
        onClose={handleClose}
        title={titleDialog}
      />
    </div>
  );
};

export default Settings;
