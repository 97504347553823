import React from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import { Button, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLinkClickHandler, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useState, useEffect } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import { Stack } from "@mui/system";
import LanguageIcon from "@mui/icons-material/Language";
import lang from "../lang/language.json";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const cookies = new Cookies();

const Header = ({ open, setOpen, title }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  // english, french, chinese
  const [languages, setLanguages] = useState([
    { name: "English", code: "en" },
    { name: "Française", code: "fr" },
    { name: "简体中文 (simplified)", code: "zh" },
    { name: "繁體中文 (traditional)", code: "zht" },
    { name: "Русский", code: "ru" },
    { name: "Español", code: "es" },
    { name: "日本", code: "ja" },
    { name: "한국인", code: "ko" },
    { name: "Tiếng Việt", code: "vi" },
    { name: "bahasa Indonesia", code: "id" },
    { name: "Türkçe", code: "tr" },
    { name: "ประเทศไทย", code: "th" },
    { name: "عربي", code: "ar" },
    { name: "hollandsk", code: "nl" },
    { name: "Portugal", code: "pt" },
  ]);
  const [text, setText] = useState({});
  const [curr_pwd, setCurr_pwd] = useState("");
  const [new_pwd, setNew_pwd] = useState("");
  const [confirm_pwd, setConfirm_pwd] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
    }

    setText(lang[localStorage.getItem("language")]);
  }, []);

  const [open4, setOpen4] = useState(false);

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    try {
      if (cookies.get("user")) {
        setUser(cookies.get("user"));
      }

      setTimeout(() => {
        axios
          .post(process.env.REACT_APP_API_URL + "/getUser", {
            refreshToken: cookies.get("refreshToken"),
          })
          .then((res) => {
            //console.log(res.status);
            if (res.status === 200) {
              setUser(res.data.user);
              cookies.set("user", res.data.user);
            }
          })
          .catch((err) => {
            //logout();
          });
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const logout = () => {
    cookies.remove("refreshToken");
    navigate("/login");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open3 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const check_pwd = () => {
    if (new_pwd !== confirm_pwd) {
      alert("Passwords do not match");
      return false;
    }

    if (new_pwd.length < 8) {
      alert("Password must be at least 8 characters");
      return false;
    }

    axios
      .post(process.env.REACT_APP_API_URL + "/changePassword", {
        refreshToken: cookies.get("refreshToken"),
        curr_pwd: curr_pwd,
        new_pwd: new_pwd,
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Password changed successfully");
          handleClose4();
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      });
  };

  const handleClickMenu = (url) => {
    window.location.assign(url);
    const currentUrl = window.location.href;
    if (currentUrl.includes(url)) {
      window.location.reload();
    }
  };

  const [website_settings, setWebsite_settings] = useState(true);
  const [user_system, setUser_system] = useState(true);
  const [withdraw_fund, setWithdraw_fund] = useState(true);
  const [partner_system, setPartner_system] = useState(true);
  const [transfer, setTransfer] = useState(true);

  return (
    <div>
      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {text["Change password"]}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={text["Old password"]}
            type="password"
            fullWidth
            onChange={(e) => setCurr_pwd(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={text["New password"]}
            type="password"
            fullWidth
            onChange={(e) => setNew_pwd(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={text["Confirm new password"]}
            type="password"
            fullWidth
            onChange={(e) => setConfirm_pwd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4}></Button>
          <Button onClick={check_pwd} autoFocus>
            {text.save}
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mr="20px"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {title}
            </Typography>
          </Toolbar>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                maxHeight: 45 * 4.5,
                width: "20ch",
              },
            }}
          >
            {languages.map((option) => (
              <MenuItem
                key={option.code}
                selected={option.code === localStorage.getItem("language")}
                onClick={() => {
                  handleClose2();
                  localStorage.setItem("language", option.code);
                  window.location.reload();
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Language">
              <IconButton
                aria-label="more"
                color="inherit"
                id="long-button"
                aria-controls={open2 ? "long-menu" : undefined}
                aria-expanded={open2 ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick2}
              >
                <Stack>
                  <LanguageIcon />
                </Stack>
              </IconButton>
            </Tooltip>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open3 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user.login ? user.login.slice(0, 1) : "A"}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open3}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClickOpen4}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          {text["Change password"]}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {text.logout}
        </MenuItem>
      </Menu>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography
            variant="h6"
            style={{ marginLeft: "-5px" }}
            noWrap
            component="div"
          >
            {text["Your ID"]}: {user.id}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton
            onClick={() => setWebsite_settings(!website_settings)}
          >
            <ListItemText primary={text["Website Setting"]} />
            {website_settings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={website_settings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/settings");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.site_settings} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/mining_pool_settings");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.mining_pool} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/news_management");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.news_management} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/add_script");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.add_script} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/social_change");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.social_change} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/integral_statistics");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.integral_statistics} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/statistics");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.statistics} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setUser_system(!user_system)}>
            <ListItemText primary={text["User System"]} />
            {user_system ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={user_system} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/users");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.user_management} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/popups");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.users_popups} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/presale_management");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.presale_management} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/staking_management");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.staking_management} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/auth_records");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.authorization_records} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/withrawal_requests");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.withdrawal_requests} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/monitoring_address");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text.monitoring_address} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setWithdraw_fund(!withdraw_fund)}>
            <ListItemText primary={text["Withdraw Funds"]} />
            {withdraw_fund ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={withdraw_fund} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/authorization_withdraw");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text["Authorization withdraw"]} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleClickMenu("/permission_settings");
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={text["Permission Withdraw"]} />
                </ListItemButton>
              </ListItem>
              {localStorage.getItem("role") !== "admin" ? (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleClickMenu("/transfers_partner");
                    }}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={text["Withdrawal Records"]} />
                  </ListItemButton>
                </ListItem>
              ) : (
                <> </>
              )}
            </List>
          </Collapse>

          {localStorage.getItem("role") === "admin" ? (
            <>
              <ListItemButton
                onClick={() => setPartner_system(!partner_system)}
              >
                <ListItemText primary={text["Partner System"]} />
                {partner_system ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={partner_system} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/register_partner");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text.register_partner} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/parnters_management");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text.partner_management} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/partners_requests");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text["Partners Requests"]} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/change_auth");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text.change_auth} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </>
          ) : (
            <></>
          )}

          {localStorage.getItem("role") === "admin" ? (
            <>
              <ListItemButton onClick={() => setTransfer(!transfer)}>
                <ListItemText primary={text["Transfer"]} />
                {transfer ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={transfer} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/transfer_funds");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text.transfer_funds} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickMenu("/transfers");
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={text["Transfers"]} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </>
          ) : (
            <></>
          )}
        </List>

        <Typography
          variant="h6"
          style={{ textAlign: "center" }}
          component="div"
        >
          {text["Your Website"]}:{" "}
          <a
            target={"_blank"}
            href={`${process.env.REACT_APP_SITE_DOMAIN}/?id=${user.id}`}
            rel="noreferrer"
          >
            {process.env.REACT_APP_SITE_DOMAIN}/?id={user.id}
          </a>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_SITE_DOMAIN}/?id=${user.id}`
              );
              alert("Copied");
            }}
          >
            <ContentCopyIcon />
          </Button>
        </Typography>
      </Drawer>
    </div>
  );
};

export default Header;
